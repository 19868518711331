import { ActionTree } from 'vuex'
import { CartDataInitialState } from './types'
import { State } from '@/main/store'
import { GET_CART_DATA, GET_CART_LOADING } from './mutations'

import { CartModel } from '@/models'

const actions: ActionTree<CartDataInitialState, State> = {
  fetchCart({ commit }) {
    commit(GET_CART_LOADING, true)
    return new Promise<void>((resolve, reject) => {
      CartModel.list()
        .then(res => {
          commit(GET_CART_LOADING, false)
          commit(GET_CART_DATA, res.data)
          resolve()
        })
        .catch(err => {
          commit(GET_CART_LOADING, false)
          reject(err)
        })
    })
  },
}

export default actions
