import { ActionTree } from 'vuex'
import { ClassroomDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GETTING_CLASSROOM_VIDEO_DATA,
  GET_CLASSROOM_VIDEO_DATA,
  GET_CLASSROOM_VIDEO_PAGE,
  GETTING_CLASSROOM_ACCESSORY_DATA,
  GET_CLASSROOM_ACCESSORY_DATA,
  GET_CLASSROOM_ACCESSORY_PAGE,
  GETTING_DETAILCLASSROOM_DATA,
  GET_DETAILCLASSROOM_DATA,
  GETTING_CLASSROOM_CERTIFICATE_DATA,
  GET_CLASSROOM_CERTIFICATE_DATA,
  GET_CLASSROOM_CERTIFICATE_PAGE,
  GETTING_CLASSROOM_ALL_DATA,
  GET_CLASSROOM_ALL_DATA,
  GETTING_CLASSROOM_WEBINAR_DATA,
  GET_CLASSROOM_WEBINAR_DATA,
  GETTING_CLASSROOM_WEBINAR_RECORD_DATA,
  GET_CLASSROOM_WEBINAR_RECORD_DATA,
  GETTING_CLASSROOM_SUBJECT_DATA,
  GET_CLASSROOM_SUBJECT_DATA,
  GETTING_CLASSROOM_CODE,
  GET_CLASSROOM_PRIVATE_WEBINAR,
} from './mutations'

import { ClassroomModel, AccessoryModel } from '@/models'

const actions: ActionTree<ClassroomDataInitialState, State> = {
  fetchClassroomVideo({ commit }, { page, showPerPage }) {
    commit(GETTING_CLASSROOM_VIDEO_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.loadMyClassroomList(page, showPerPage)
        .then(response => {
          const { classrooms, pagination } = response.data
          commit(GET_CLASSROOM_VIDEO_DATA, classrooms)
          commit(GET_CLASSROOM_VIDEO_PAGE, pagination)
          commit(GETTING_CLASSROOM_VIDEO_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  fetchClassroomAccessory({ commit }, { page, showPerPage }) {
    commit(GETTING_CLASSROOM_ACCESSORY_DATA, true)
    return new Promise<void>((resolve, reject) => {
      AccessoryModel.loadAccessoriesList(page, showPerPage)
        .then(response => {
          commit(GET_CLASSROOM_ACCESSORY_DATA, response.data)
          commit(GET_CLASSROOM_ACCESSORY_PAGE, response.pages)
          commit(GETTING_CLASSROOM_ACCESSORY_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  fetchClassroomCertificate({ commit }, { page, showPerPage }) {
    commit(GETTING_CLASSROOM_CERTIFICATE_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.loadMyClassroomCertificateListV2(page, showPerPage)
        .then(response => {
          const { certificates, pagination } = response.data
          commit(GET_CLASSROOM_CERTIFICATE_DATA, certificates)
          commit(GET_CLASSROOM_CERTIFICATE_PAGE, pagination)
          commit(GETTING_CLASSROOM_CERTIFICATE_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  fetchDetailClassroom({ commit }, classroomLevelCode: string) {
    commit(GETTING_DETAILCLASSROOM_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.detailClassroomLevel(classroomLevelCode)
        .then(response => {
          commit(GET_DETAILCLASSROOM_DATA, response.data)
          commit(GETTING_DETAILCLASSROOM_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },

  fetchClassroomAll({ commit }) {
    commit(GETTING_CLASSROOM_ALL_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listAllPrivate()
        .then(response => {
          // console.log('fromvuex', response.data)
          commit(GET_CLASSROOM_ALL_DATA, response.data)
          commit(GETTING_CLASSROOM_ALL_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },

  // Webinar
  fetchClassroomWebinar({ commit }, levelId: string) {
    commit(GETTING_CLASSROOM_WEBINAR_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listWebinarByClassLevel(levelId)
        .then(response => {
          commit(GET_CLASSROOM_WEBINAR_DATA, response.data)
          commit(GETTING_CLASSROOM_WEBINAR_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  // Webinar Record
  fetchClassroomWebinarRecord({ commit }, levelId: number) {
    commit(GETTING_CLASSROOM_WEBINAR_RECORD_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listWebinarRecordByClassLevel(levelId)
        .then(response => {
          commit(GET_CLASSROOM_WEBINAR_RECORD_DATA, response.data)
          commit(GETTING_CLASSROOM_WEBINAR_RECORD_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  // Classrom Subject
  fetchClassroomSubject({ commit }, classroomLevelCode: string) {
    commit(GETTING_CLASSROOM_SUBJECT_DATA, true)
    commit(GETTING_CLASSROOM_CODE, classroomLevelCode)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listSubject(classroomLevelCode)
        .then(response => {
          commit(GET_CLASSROOM_SUBJECT_DATA, response.data)
          commit(GETTING_CLASSROOM_SUBJECT_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },

  // Classroom Private Webinar
  fetchClassroomPrivateWebinar({ commit }, classroomCode: string) {
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.getListPrivateWebinar(classroomCode)
        .then(response => {
          commit(GET_CLASSROOM_PRIVATE_WEBINAR, response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default actions
