import { createApp } from 'vue'
import App from './App.vue'
import '../registerServiceWorker'
import router from '../router/index'
import { store } from '../main/store'
import filters from '@/filters'
import Back from '@/main/components/Back.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import Auth from '@/utils/Auth'
import './../../public/css/main.css'
import './../../public/css/sekdes.css'
import Tabs from 'vue3-tabs'
import * as Sentry from '@sentry/browser'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import { dragscrollNext } from 'vue-dragscroll'
import VueSmoothScroll from 'vue3-smooth-scroll'
import { Integrations } from '@sentry/tracing'
import VCalendar from 'v-calendar'
import VTooltipPlugin from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'
import VueClickAway from 'vue3-click-away'
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Paginator from 'primevue/paginator'
import Toast from 'vue-toastification'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueCropper from 'vue-cropperjs'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'

import 'cropperjs/dist/cropper.css'
import 'vue-toastification/dist/index.css'
import 'primeicons/primeicons.css'
// import 'primevue/resources/themes/saga-blue/theme.css'
// import 'primevue/resources/themes/tailwind-light/theme.css'
import 'primevue/resources/themes/fluent-light/theme.css'
import 'primevue/resources/primevue.min.css'
import '@/assets/styles/main.css'
import 'vue3-carousel/dist/carousel.css'

const app = createApp(App)
  .use(store)
  .use(router)
  .use(VuePlyr, {
    plyr: {},
  })
  .use(VueSmoothScroll, {
    duration: 500,
    updateHistory: false,
  })
  .use(VCalendar)
  .use(VueClickAway)
  .use(VTooltipPlugin, {
    theme: {
      webinar: {
        tooltip: {
          $resetCss: true,
          triggers: ['click'],
          placement: 'top',
          autoHide: true,
          handleResize: true,
          html: true,
        },
      },
    },
  })
  .use(Tabs)
  .use(PrimeVue)
  .use(Toast)
  .use(CKEditor)
  .use(VueCropper)
app.config.globalProperties.$filters = {
  ...filters,
}

Sentry.init({
  dsn: process.env.VUE_DNS_SENTRY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

app.component('Back', Back)
app.component('v-select', vSelect)
app.component('vueper-slides', VueperSlides)
app.component('vueper-slide', VueperSlide)
app.directive('dragscroll', dragscrollNext)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Paginator', Paginator)
app.component('Dropdown', Dropdown)
app.component('InputNumber', InputNumber)
app.component('cropper', Cropper)
app.directive('focus', {
  mounted(el) {
    el.focus()
  },
})

app.mount('#app')

router.beforeEach((to, from, next) => {
  const isLoggedIn = Auth.isLoggedIn

  if (to.matched.some(record => record.meta.requiresAuth) && !isLoggedIn) {
    next('/login')
  }

  next()
})
