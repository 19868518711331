import { HomeDataInitialState } from './types'

export const GETTING_CATEGORY_DATA = 'GETTING_CATEGORY_DATA'
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA'
export const GET_CATEGORY_PAGE = 'GETTING_CATEGORY_PAGE'
export const GETTING_SELF_CLASSROOM_DATA = 'GETTING_SELF_CLASSROOM_DATA'
export const GET_SELF_CLASSROOM_DATA = 'GET_SELF_CLASSROOM_DATA'
export const GET_SELF_CLASSROOM_PAGE = 'GETTING_CLASSROOM_PAGE'
export const GET_MY_WEBINAR_DATA = 'GET_MY_WEBINAR_DATA'
export const GETTING_MY_WEBINAR_DATA = 'GETTING_MY_WEBINAR_DATA'

export default {
  [GETTING_CATEGORY_DATA](state: HomeDataInitialState, values: boolean) {
    state.homeCategoryLoading = values
  },
  [GET_CATEGORY_DATA](state: HomeDataInitialState, values: Array<any>) {
    state.homeCategoryData = values
  },
  [GET_CATEGORY_PAGE](state: HomeDataInitialState, values: object) {
    state.homeCategoryPages = values
  },
  [GETTING_SELF_CLASSROOM_DATA](state: HomeDataInitialState, values: boolean) {
    state.homeSelfClassroomDataLoading = values
  },
  [GET_SELF_CLASSROOM_DATA](state: HomeDataInitialState, values: Array<any>) {
    state.homeSelfClassroomData = values
  },
  [GET_SELF_CLASSROOM_PAGE](state: HomeDataInitialState, values: object) {
    state.homeSelfClassroomDataPages = values
  },
  [GET_MY_WEBINAR_DATA](state: HomeDataInitialState, values: Array<any>) {
    state.myWebinarData = values
  },
  [GETTING_MY_WEBINAR_DATA](state: HomeDataInitialState, values: boolean) {
    state.myWebinarDataLoading = values
  },
}
