import Notification from '@/dto/Notification'
import Pagination from '@/dto/Response/Pagination'
import { NotificationDataInitialState } from './types'

const state: NotificationDataInitialState = {
  notificationDataLoading: true,
  notificationData: [] as Notification[],
  notificationDataPagination: {} as Pagination,
  unReadNotificationData: [] as Notification[],
}

export default state
