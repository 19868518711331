import { ClassroomDataInitialState } from './types'
import { ClassroomDetail, ClassroomItems } from '@/dto/ClassroomV2'
import { MediaVideo } from '@/dto/Media'
import PaginationV2 from '@/dto/Response/PaginationV2'
import {
  Accessory,
  Classroom,
  ClassroomLevelWebinar,
  Subject,
  PrivateWebinar,
} from '@/dto'
import Pagination from '@/dto/Response/Pagination'
import { CertificateItem } from '@/dto/CertificateV2'

export const GETTING_CLASSROOM_VIDEO_DATA = 'GETTING_CLASSROOM_VIDEO_DATA'
export const GET_CLASSROOM_VIDEO_DATA = 'GET_CLASSROOM_VIDEO_DATA'
export const GET_CLASSROOM_VIDEO_PAGE = 'GET_CLASSROOM_VIDEO_PAGE'

export const GETTING_CLASSROOM_ACCESSORY_DATA =
  'GETTING_CLASSROOM_ACCESSORY_DATA'
export const GET_CLASSROOM_ACCESSORY_DATA = 'GET_CLASSROOM_ACCESSORY_DATA'
export const GET_CLASSROOM_ACCESSORY_PAGE = 'GET_CLASSROOM_ACCESSORY_PAGE'

export const GETTING_DETAILCLASSROOM_DATA = 'GETTING_DETAILCLASSROOM_DATA'
export const GET_DETAILCLASSROOM_DATA = 'GET_DETAILCLASSROOM_DATA'
export const GETTING_DETAILCLASSROOMVIDEO_DATA =
  'GETTING_DETAILCLASSROOMVIDEO_DATA'
export const GET_DETAILCLASSROOMVIDEO_DATA = 'GET_DETAILCLASSROOMVIDEO_DATA'

// Certificate
export const GETTING_CLASSROOM_CERTIFICATE_DATA =
  'GETTING_CLASSROOM_CERTIFICATE_DATA'
export const GET_CLASSROOM_CERTIFICATE_DATA = 'GET_CLASSROOM_CERTIFICATE_DATA'
export const GET_CLASSROOM_CERTIFICATE_PAGE = 'GET_CLASSROOM_CERTIFICATE_PAGE'

// Classroom
export const GETTING_CLASSROOM_ALL_DATA = 'GETTING_CLASSROOM_ALL_DATA'
export const GET_CLASSROOM_ALL_DATA = 'GET_CLASSROOM_ALL_DATA'

// Webinar
export const GETTING_CLASSROOM_WEBINAR_DATA = 'GETTING_CLASSROOM_WEBINAR_DATA'
export const GET_CLASSROOM_WEBINAR_DATA = 'GET_CLASSROOM_WEBINAR_DATA'

// Webinar Record
export const GETTING_CLASSROOM_WEBINAR_RECORD_DATA =
  'GETTING_CLASSROOM_WEBINAR_RECORD_DATA'
export const GET_CLASSROOM_WEBINAR_RECORD_DATA =
  'GET_CLASSROOM_WEBINAR_RECORD_DATA'

// Classroom subject
export const GETTING_CLASSROOM_SUBJECT_DATA = 'GETTING_CLASSROOM_SUBJECT_DATA'
export const GET_CLASSROOM_SUBJECT_DATA = 'GET_CLASSROOM_SUBJECT_DATA'

// Private Webinar
export const GET_CLASSROOM_PRIVATE_WEBINAR = 'GET_CLASSROOM_PRIVATE_WEBINAR'

// Classroom Code
export const GETTING_CLASSROOM_CODE = 'GETTING_CLASSROOM_CODE'

export default {
  [GETTING_CLASSROOM_VIDEO_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomVideoDataLoading = values
  },
  [GET_CLASSROOM_VIDEO_DATA](
    state: ClassroomDataInitialState,
    values: Array<ClassroomItems>
  ) {
    state.classroomVideoData = state.classroomVideoData.concat(values)
  },
  [GET_CLASSROOM_VIDEO_PAGE](
    state: ClassroomDataInitialState,
    values: PaginationV2
  ) {
    state.classroomVideoDataPages = values
  },

  [GETTING_CLASSROOM_ACCESSORY_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomAccessoryDataLoading = values
  },
  [GET_CLASSROOM_ACCESSORY_DATA](
    state: ClassroomDataInitialState,
    values: Array<Accessory>
  ) {
    state.classroomAccessoryData = state.classroomAccessoryData.concat(values)
  },
  [GET_CLASSROOM_ACCESSORY_PAGE](
    state: ClassroomDataInitialState,
    values: Pagination
  ) {
    state.classroomAccessoryDataPages = values
  },
  [GETTING_CLASSROOM_CERTIFICATE_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomCertificateDataLoading = values
  },
  [GET_CLASSROOM_CERTIFICATE_DATA](
    state: ClassroomDataInitialState,
    values: Array<CertificateItem>
  ) {
    state.classroomCertificateData = state.classroomCertificateData.concat(
      values
    )
  },
  [GET_CLASSROOM_CERTIFICATE_PAGE](
    state: ClassroomDataInitialState,
    values: PaginationV2
  ) {
    state.classroomCertificateDataPages = values
  },
  [GETTING_DETAILCLASSROOM_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.detailClassroomDataLoading = values
  },
  [GET_DETAILCLASSROOM_DATA](
    state: ClassroomDataInitialState,
    values: ClassroomDetail
  ) {
    state.detailClassroomData = values
  },
  [GETTING_DETAILCLASSROOMVIDEO_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.detailClassroomVideoDataLoading = values
  },
  [GET_DETAILCLASSROOMVIDEO_DATA](
    state: ClassroomDataInitialState,
    values: Array<MediaVideo>
  ) {
    state.detailClassroomVideoData = values
  },

  // All CLassroom
  [GETTING_CLASSROOM_ALL_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomAllDataLoading = values
  },
  [GET_CLASSROOM_ALL_DATA](
    state: ClassroomDataInitialState,
    values: Array<Classroom>
  ) {
    state.classroomAllData = state.classroomAllData.concat(values)
  },

  // Webinar
  [GETTING_CLASSROOM_WEBINAR_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomWebinarLoading = values
  },
  [GET_CLASSROOM_WEBINAR_DATA](
    state: ClassroomDataInitialState,
    values: Array<ClassroomLevelWebinar>
  ) {
    state.classroomWebinarData = values
  },

  // Webinar Record
  [GETTING_CLASSROOM_WEBINAR_RECORD_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomWebinarRecordLoading = values
  },
  [GET_CLASSROOM_WEBINAR_RECORD_DATA](
    state: ClassroomDataInitialState,
    values: Array<ClassroomLevelWebinar>
  ) {
    state.classroomWebinarRecordData = values
  },

  // Classroom Subject
  [GETTING_CLASSROOM_SUBJECT_DATA](
    state: ClassroomDataInitialState,
    values: boolean
  ) {
    state.classroomSubjectLoading = values
  },
  [GET_CLASSROOM_SUBJECT_DATA](
    state: ClassroomDataInitialState,
    values: Array<Subject>
  ) {
    state.classroomSubjectData = values
  },

  // Classroom Private Webinar
  [GET_CLASSROOM_PRIVATE_WEBINAR](
    state: ClassroomDataInitialState,
    values: Array<PrivateWebinar>
  ) {
    state.classroomPrivateWebinarData = values
  },

  // Classroom Code
  [GETTING_CLASSROOM_CODE](state: ClassroomDataInitialState, values: string) {
    state.classroomCode = values
  },
}
