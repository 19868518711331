import { User, Classroom } from '@/dto'
import Pagination from '@/dto/Response/Pagination'
import { SidebarDataInitialState } from './types'

const state: SidebarDataInitialState = {
  // user state
  sidebarDataUserLoading: true,
  sidebarDataUser: {} as User,

  // classroom state
  sidebarDataClassroomLoading: true,
  sidebarDataClassroom: [] as Classroom[],
  sidebarDataClassroomPagination: {} as Pagination,
}

export default state
