import { RouteLocationNormalized } from 'vue-router'

export default {
  path: '/timeout',
  name: 'TimeoutError',
  component: () =>
    import(
      /* webpackChunkName: 'App.TimeoutError' */ '@/main/views/errors/timeout.vue'
    ),
  meta: {
    title: 'Timeout',
  },
  beforeEnter: (
    _: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: Function
  ) => {
    if (!from.name) {
      return next('/')
    }
    return next()
  },
}
