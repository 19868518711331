import { ActionTree } from 'vuex'
import Cookies, { get } from 'js-cookie'
import { SidebarDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GETTING_USERSIDEBAR_DATA,
  GET_USERSIDEBAR_DATA,
  GETTING_CLASSROOMSIDEBAR_DATA,
  GET_CLASSROOMSIDEBAR_DATA,
  GET_CLASSROOMSIDEBAR_PAGINATION_DATA,
} from './mutations'

import UserModel from '@/models/UserModel'
import ClassroomModel from '@/models/ClassroomModel'

const AuthObj = Cookies.getJSON('auth')

const actions: ActionTree<SidebarDataInitialState, State> = {
  // get user information, contain loading & data
  fetchUserSidebarData({ commit }) {
    commit(GETTING_USERSIDEBAR_DATA, true)
    return new Promise<void>((resolve, reject) => {
      UserModel.me(AuthObj.token)
        .then(response => {
          commit(GET_USERSIDEBAR_DATA, response.data)
          commit(GETTING_USERSIDEBAR_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // get user classroom, contain loading, data classroom & pagination
  fetchClassroomSidebarData({ commit }, loadAmount: number) {
    commit(GETTING_CLASSROOMSIDEBAR_DATA, true)
    return new Promise<void>((resolve, reject) => {
      // Load Classroom with 10 show per page
      ClassroomModel.loadClassroomList(loadAmount)
        .then(response => {
          commit(GET_CLASSROOMSIDEBAR_DATA, response.data)
          commit(GET_CLASSROOMSIDEBAR_PAGINATION_DATA, response.pages)
          commit(GETTING_CLASSROOMSIDEBAR_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default actions
