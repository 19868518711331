/* eslint-disable @typescript-eslint/camelcase */
import Cookies from 'js-cookie'
import qs from 'qs'
import UserModel from '@/models/UserModel'
import EmailTemplateModel from '@/models/EmailTemplateModel'
import VoucherClassroomModel from '@/models/VoucherClassroomModel'
import MigrateUserDataModel from '@/models/MigrateUserDataModel'
import MainApi from '@/utils/Http'
import ClassroomModel from '@/models/ClassroomModel'
const Http = MainApi.getInstance()

export type Token = {
  token: string
  refresh_token: string
  is_imported: boolean | undefined
}

export default class Auth {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` + `${process.env.VUE_APP_SERVICE_USER}`
  protected idp = `${process.env.VUE_APP_IDP_URL}`
  protected secret = `${process.env.VUE_APP_IDP_CLIENT_SECRET}`
  protected oldEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_USER_OLD}`

  static isLoggedIn() {
    return !!Cookies.getJSON('auth')
  }

  static isAdmin(): boolean {
    if (Cookies.getJSON('roleId') === 1) {
      return true
    } else {
      return false
    }
  }

  static isMentor(): boolean {
    if (Cookies.getJSON('roleId') === 2) {
      return true
    } else {
      return false
    }
  }

  static isMember(): boolean {
    if (Cookies.getJSON('roleId') === 3) {
      return true
    } else {
      return false
    }
  }

  static isFailedRedemPrakerja(
    isFromPrakerja: boolean,
    status: boolean
  ): boolean {
    if (isFromPrakerja && status) {
      // success redeem
      return false
    } else {
      // failed redeem
      return true
    }
  }

  static isFromOneRupiah(): boolean {
    if (window.location.pathname === '/one-rupiah') {
      return true
    } else {
      return false
    }
  }

  static isFromClassroomCart(): boolean {
    if (window.location.pathname === '/kelas') {
      return true
    } else {
      return false
    }
  }

  static isFromFreeClass(): boolean {
    const path = window.location.pathname.split('/')[1]
    if (path === 'checkout-free') {
      return true
    } else {
      return false
    }
  }

  static async idpLogin(
    username: string,
    password: string,
    alreadyRegistered: boolean
  ) {
    const sso = new this().idp

    const LoginData = qs.stringify({
      grant_type: 'password',
      client_id: 'kong',
      client_secret: process.env.VUE_APP_IDP_CLIENT_SECRET,
      username: username,
      password: password,
    })

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }

    await Http.post(`${sso}`, LoginData, { headers }).then(res => {
      const user = {
        token: res.data.access_token,
        refresh_token: res.data.refresh_token,
        is_imported: null,
        registered: alreadyRegistered,
      }
      Cookies.set('auth', user)
      this.checkUser(user, false, false)
    })
  }

  static throwLoginError() {
    throw new Error('Gagal Login')
  }

  static async checkOldUserLogin(userCreds: any) {
    const oldEndpoint = new this().oldEndpoint

    const data = await Http.post(`${oldEndpoint}`, userCreds).then(
      async res => {
        if (res.data.registered && res.data.success) {
          return await this.newRegister(
            res.data.data.name,
            userCreds.email,
            res.data.data.whatsapp,
            res.data.data.city_id,
            userCreds.password
          )
          // try {
          //   await this.newR  egister(
          //     res.data.data.name,
          //     userCreds.email,
          //     res.data.data.whatsapp,
          //     res.data.data.city_id,
          //     userCreds.password
          //   )

          //   // await this.idpLogin(userCreds.email, userCreds.password, false)
          // } catch (error) {
          //   const err: any = error
          //   return new TypeError(err.message)
          // }
          // finally {
          //   console.log('Cleaning')
          // }
        } else {
          return false
        }
      }
    )
    return data
  }

  static async checkLogin(userCreds: any) {
    try {
      await this.idpLogin(userCreds.email, userCreds.password, true)
    } catch (error) {
      const err: any = error
      if (err?.response?.data?.is_must_reset_password) {
        return 'reset-password'
      } else {
        if (err?.response?.data?.is_must_verify) {
          return 'verify-email'
        }
        const data = await this.checkOldUserLogin(userCreds)
        return data
      }
    }
  }

  static async Login(username: string, password: string) {
    const userCreds = {
      email: username,
      password: password,
    }

    const data = await this.checkLogin(userCreds)
      .then(async res => {
        return res
      })
      .catch(err => {
        return err
      })
    return {
      data,
    }
  }

  static async LoginRedeem(
    username: string,
    password: string,
    voucher: string
  ) {
    const sso = new this().idp

    const LoginData = qs.stringify({
      grant_type: 'password',
      client_id: 'kong',
      client_secret: process.env.VUE_APP_IDP_CLIENT_SECRET,
      username: username,
      password: password,
    })

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }

    const RedeemData = await Http.post(`${sso}`, LoginData, { headers })
      .then(async res => {
        const user = {
          token: res.data.access_token,
          refresh_token: res.data.refresh_token,
          is_imported: null,
          registered: false,
        }

        Cookies.set('auth', user)

        const { success } = await VoucherClassroomModel.redeem(voucher)
        if (success) {
          await this.checkUser(user, true, true)
        } else {
          return res.data.success
        }
      })
      .catch(err => {
        return err.response.data
      })

    return {
      RedeemData,
    }
  }

  static logout(): void {
    Cookies.remove('auth')
    Cookies.remove('roleId')
  }

  static async user(token: string) {
    await UserModel.me(token)
    // return Cookies.getJSON('auth')
  }

  static async checkOldUserRegister(userCreds: any) {
    const oldEndpoint = new this().oldEndpoint

    const userCheckCreds = {
      email: userCreds.email,
      password: userCreds.password,
    }

    const res = await Http.post(`${oldEndpoint}`, userCheckCreds)

    // Jika user sudah register
    if (res.data.registered) {
      return 'isExistingUser'
    }

    try {
      if (userCreds.city_id) {
        const data = await this.newRegister(
          userCreds.name,
          userCreds.email,
          userCreds.whatsapp,
          userCreds.city_id,
          userCreds.password
        )
        return {
          data,
        }
      } else {
        const data = await this.newRegister(
          userCreds.name,
          userCreds.email,
          userCreds.whatsapp,
          0,
          userCreds.password
        )
        return {
          data,
        }
      }
    } catch (error) {
      const err: any = error
      throw new TypeError(err.message)
    }
  }

  static async register(
    name: string,
    email: string,
    whatsapp: string,
    city_id: number,
    password: string
  ) {
    let userCreds = {}
    if (city_id == 0) {
      userCreds = {
        name: name,
        email: email,
        whatsapp: whatsapp,
        password: password,
      }
    } else {
      userCreds = {
        name: name,
        email: email,
        whatsapp: whatsapp,
        city_id: city_id,
        password: password,
      }
    }

    try {
      const data = await this.checkOldUserRegister(userCreds)
      return {
        data,
      }
    } catch (error) {
      if (error) {
        return error
      }
    }
  }

  static async newRegister(
    name: string,
    email: string,
    whatsapp: string,
    city_id: number,
    password: string
  ) {
    const endpoint = new this().endpoint + '/user'
    let userCreds = {}
    if (city_id == 0) {
      userCreds = {
        name: name,
        email: email,
        whatsapp: whatsapp,
        password: password,
      }
    } else {
      userCreds = {
        name: name,
        email: email,
        whatsapp: whatsapp,
        city_id: city_id,
        password: password,
      }
    }

    try {
      const regsData = await Http.post(endpoint, userCreds, {
        headers: {
          'Content-Type': 'application/json',
          'X-Update': 'new',
        },
      })
        .then(async res => {
          try {
            await this.idpLogin(email, password, false)
          } catch (error) {
            const err: any = error
            if (err?.response?.data?.is_must_verify) {
              return 'verify-email'
            }
          }
          return res.data.data
        })
        .catch(err => {
          return err.response.data
        })

      return {
        regsData,
      }
    } catch (error) {
      const err: any = error
      return err.response.data
    }
  }

  static async registerPrakerja(
    name: string,
    email: string,
    whatsapp: string,
    city_id: number,
    password: string,
    voucher: string
  ) {
    const userCreds = {
      name: name,
      email: email,
      whatsapp: whatsapp,
      city_id: city_id,
      password: password,
    }

    try {
      const data = await this.checkOldUserRegister(userCreds)
      if (data != 'isExistingUser') {
        try {
          const data = await this.LoginRedeem(
            userCreds.email,
            userCreds.password,
            voucher
          )
          return data
        } catch (err) {
          return err
        }
      } else {
        return data
      }
    } catch (error) {
      if (error) {
        return error
      }
    }
  }

  public static getToken(): Token | undefined {
    return Cookies.getJSON('auth')
  }

  public static setToken(token: Token) {
    Cookies.set('auth', token)
  }

  public static async checkUser(
    user: {
      token: string
      refresh_token: string
      registered: boolean
    },
    isFromPrakerja: boolean,
    successRedeem: boolean
  ) {
    try {
      const res = await MigrateUserDataModel.userStatus()
      const userSession = {
        ...user,
        is_imported: res.data.is_imported,
      }
      // console.log('migrate check', res)
      // console.log('user check', user)
      const isNeedImportData = !res.data.is_imported

      // Comment this code temporary, will use this for sending email when register
      // if (!user.registered) {
      // await EmailTemplateModel.getRegisterEmail(user.token)
      // try {
      //   const { success } = await ClassroomModel.assignFreeClassroomNewUser(
      //     user.token
      //   )
      //   if (success) {
      //     Cookies.set('freeclass', 'true')
      //   } else {
      //     Cookies.set('freeclass', 'false')
      //   }
      // } catch (error) {
      //   Cookies.set('freeclass', 'false')
      // }
      // }

      if (res.success && isNeedImportData) {
        Cookies.set('auth', userSession)
        if (isFromPrakerja) {
          if (
            this.isFailedRedemPrakerja(isFromPrakerja, successRedeem) == true
          ) {
            return
          } else {
            window.location.assign('/process-data')
          }
        } else {
          window.location.assign('/process-data')
        }
      } else {
        this.user(user.token).then(() => {
          if (!isFromPrakerja) {
            if (
              this.isFromOneRupiah() == false &&
              this.isFromFreeClass() == false &&
              this.isFromClassroomCart() == false
            ) {
              if (this.isMentor() == true) {
                window.location.assign('/mentor')
              } else {
                window.location.assign('/')
              }
              // if (this.isAdmin() == true) {
              //   window.location.assign('/admin')
              // }
              // if (this.isAdmin() == false) {
              //   window.location.assign('/')
              // }
            } else {
              return
            }
          } else {
            if (this.isFailedRedemPrakerja(isFromPrakerja, successRedeem)) {
              return
            } else {
              window.location.assign('/')
            }
          }

          Cookies.set('auth', userSession)
        })
      }
    } catch (error) {
      const userSession = {
        ...user,
        is_imported: null,
      }
      Cookies.set('auth', userSession)
    }
  }
}
