<template>
  <router-view></router-view>
  <div
    v-if="currentRouteName !== 'App.Main.Course.Activity'"
    class="floating-chat"
  >
    <a
      href="https://wa.me/6285155421080?text=Halo, Mimin Owly. Saya butuh bantuan terkait Sekolah Desain."
    >
      <img
        src="https://assets.sekolahdesain.id/setting/wa.svg"
        alt="Whatsapp Image"
        class="floating-chat-image"
      />
    </a>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
</script>

<style>
/* #app,
body,
.bg-blue-sd-3 {
  background: #00446e;
} */

.floating-chat {
  z-index: 99;
  position: fixed;
  bottom: 15px;
  right: 15px;
}

.floating-chat-image {
  width: 50px;
}

.icon-cart {
  width: 10%;
  display: inline;
}

@media (max-width: 768px) {
  /* #app {
    padding-bottom: 5rem;
  } */

  .floating-chat {
    bottom: 65px;
    right: 15px;
  }

  .floating-chat-image {
    width: 35px;
  }
}

@media (max-width: 576px) {
  .floating-chat {
    bottom: 155px;
  }
}

.bg-blue-sd-2 {
  background: #0070b5;
}

.bg-blue-sd-1 {
  background: #009dff;
}

.bg-yellow-sd-1 {
  background: #ff9f10;
}

.bg-yellow-sd-2 {
  background: #ffc200;
}

.bg-blue-sd-4 {
  background: #013454;
}

.blue-sd-1 {
  color: #009dff;
}

.blue-sd-2 {
  color: #0070b5;
}

.blue-sd-3 {
  color: #00446e;
}

.blue-sd-4 {
  color: #013454;
}

.yellow-sd-1 {
  color: #ff9f10;
}

.yellow-sd-2 {
  color: #ffc200;
}

.font-bold {
  font-weight: 700;
}

.font-bolder {
  font-weight: 900;
}

.outline-white {
  outline: 2px dotted #fff;
  outline-offset: 2px;
}

.outline-black {
  outline: 2px dotted #000;
  outline-offset: 2px;
}

.ads-video {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.ads-video-yt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
