import { GetterTree } from 'vuex'
import { ClassroomDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<ClassroomDataInitialState, State> = {
  getLoadingClassroomVideoData: state => {
    return state.classroomVideoDataLoading
  },
  getClassroomVideoData: state => {
    return state.classroomVideoData
  },
  getClassroomVideoDataPage: state => {
    return state.classroomVideoDataPages
  },
  getLoadingAccessoryVideoData: state => {
    return state.classroomAccessoryDataLoading
  },
  getAccessoryVideoData: state => {
    return state.classroomAccessoryData
  },
  getAccessoryVideoDataPage: state => {
    return state.classroomAccessoryDataPages
  },
  getLoadingClassroomCertificateData: state => {
    return state.classroomCertificateDataLoading
  },
  getClassroomCertificateData: state => {
    return state.classroomCertificateData
  },
  getClassroomCertificateDataPage: state => {
    return state.classroomCertificateDataPages
  },
  getLoadingClassroomAllData: state => {
    return state.classroomAllDataLoading
  },
  getClassroomAllData: state => {
    return state.classroomAllData
  },
  getLoadingDetailClassroom: state => {
    return state.detailClassroomDataLoading
  },
  getDetailClassroom: state => {
    return state.detailClassroomData
  },
  getDetailClassroomMentor: state => {
    return state.detailClassroomData?.mentor || []
  },
  // Webinar
  getLoadingClassroomWebinarData: state => {
    return state.classroomWebinarLoading
  },
  getClassroomWebinarData: state => {
    return state.classroomWebinarData || []
  },
  // Webinar Record
  getLoadingClassroomWebinarRecordData: state => {
    return state.classroomWebinarRecordLoading
  },
  getClassroomWebinarRecordData: state => {
    return state.classroomWebinarRecordData || []
  },
  // Classroom Subject
  getLoadingClassroomSubjectData: state => {
    return state.classroomSubjectLoading
  },
  getClassroomSubjectData: state => {
    return state.classroomSubjectData || []
  },
  getClassroomCode: state => {
    return state.classroomCode || ''
  },
  getClassroomPrivateWebinarData: state => {
    return state.classroomPrivateWebinarData || []
  },
}

export default getters
