/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { User, RequestResetPassword, CheckToken } from '@/dto'
import MainApi from '@/utils/Http'
import moment from 'moment'
import Cookies from 'js-cookie'
import CityModel from './CityModel'

const Http = MainApi.getInstance()

export default class UserModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` + `${process.env.VUE_APP_SERVICE_USER}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_USER_PRIVATE}`

  public static async me(token: string) {
    try {
      const endpoint = new this().privateEndpoint + '/me'
      const {
        data: { data, success },
      } = await Http.get(endpoint, {
        headers: { Authorization: `Bearer ${token}` },
      })
      Cookies.set('roleId', data.role_id)
      return {
        success,
        errors: [],
        data,
      }
    } catch (err) {
      Cookies.remove('auth')
      window.location.href = '/'
      return err
    }
  }

  public static async list(): Promise<ResourceResponse<User[]>> {
    const endpoint = new this().endpoint + '/user?per_page=1000000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listUserUuid(
    uuid: string
  ): Promise<ResourceResponse<User[]>> {
    const endpoint = new this().privateEndpoint + '/user/?uuid=' + uuid

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async searchlistUser(
    name: string
  ): Promise<ResourceResponse<User[]>> {
    const privateEndpoint = new this().privateEndpoint + '/user?name=' + name
    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listPrivate(perPage: number, no: number, search: string) {
    let endpoint = new this().privateEndpoint + '/user'

    if (search) {
      endpoint =
        endpoint +
        '?per_page=' +
        perPage +
        '&page=' +
        no +
        '&name=' +
        search +
        '&email=' +
        search
    } else {
      endpoint = endpoint + '?per_page=' + perPage + '&page=' + no
    }
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async add(payload: User): Promise<ResourceResponse<User>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: string) {
    const endpoint = new this().privateEndpoint + '/user'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}/show`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async editProfile(
    firstName: string,
    whatsapp: string,
    instagram: string,
    address: string,
    date: string,
    cityId: number,
    city: string,
    birthPlace: string
  ) {
    const endpoint = new this().privateEndpoint + '/me'

    const payload: any = {
      first_name: firstName,
      whatsapp: whatsapp,
      instagram: instagram,
      address: address,
      birth_place: birthPlace,
      birth_date: moment(date).format('YYYY-MM-DD 00:00:00'),
      city: city,
      city_id: cityId,
    }

    if (!instagram) {
      delete payload?.instagram
    }
    if (!address) {
      delete payload?.address
    }
    if (!birthPlace) {
      delete payload?.birth_place
    }
    if (!date) {
      delete payload?.birth_date
    }
    if (!city || city == 'Pilih Kota') {
      delete payload?.city
    }
    if (!cityId) {
      delete payload?.city_id
    }

    const {
      data: { data, success },
    } = await Http.put(endpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async editProfileMentor(
    name: string,
    whatsapp: string,
    instagram: string,
    telegram: string,
    city: string
  ) {
    const endpoint = new this().privateEndpoint + '/me'

    const payload = {
      name: name,
      whatsapp: whatsapp,
      instagram: instagram,
      telegram: telegram,
      city: city,
    }

    const {
      data: { data, success },
    } = await Http.put(endpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async editPhoto(image: any) {
    const endpoint = new this().privateEndpoint + '/me/upload'
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const formData = new FormData()
    formData.append('photo', image)
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, formData, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async uploadTwibbon(id: number, uuid: string) {
    const endpoint = new this().privateEndpoint + '/user/' + uuid + '/twibbon'
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    console.log(endpoint)
    const payload = {
      acc_id: id,
    }

    const {
      data: { data, success },
    } = await Http.put(endpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async deletePhoto(id: number) {
    const endpoint = new this().privateEndpoint
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/user/${id}/photo-remove`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: User
  ): Promise<ResourceResponse<User>> {
    const endpoint = new this().privateEndpoint + '/user'

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateUserMigrate(user: any, payload: any) {
    const cities = (await CityModel.listOrderName()).data

    const sortedCity = cities.sort(function(a, b) {
      if (a.name < b.name) return -1
      else if (a.name > b.name) return 1
      return 0
    })
    const birth_place = sortedCity[parseInt(payload.birth_place) - 1]
    const city = sortedCity[parseInt(payload.city) - 1]

    // console.log(birth_place)
    // console.log(city)
    const id = user.id
    const update = {
      role_id: payload.role_id ? payload.role_id : 3,
      activation_code: payload.activation_code ? payload.activation_code : null,
      address: payload.address ? payload.address : '',
      birth_place: payload.birth_place ? birth_place.name : '',
      birth_date: payload.birth_date
        ? moment(payload.birth_date).format('YYYY-MM-DD HH:mm:ss')
        : null,
      city: payload.city ? city.name : '',
      city_id: payload.city_id ? payload.city_id : 0,
      coin: payload.coin ? parseInt(payload.coin) : 0,
      hold_coin: payload.hold_coin ? parseInt(payload.hold_coin) : 0,
      instagram: payload.instagram ? payload.instagram : '',
      name: payload.name ? payload.name : '',
      notes: payload.notes ? payload.notes : null,
      original_photo: payload.original_photo
        ? 'https://assets.sekolahdesain.id/' + payload.original_photo
        : null,
      photo: payload.photo
        ? 'https://assets.sekolahdesain.id/' + payload.photo
        : null,
      phone: payload.phone ? payload.phone.toString() : '',
      prev_request: payload.prev_request ? payload.prev_request : null,
      telegram_authorized: payload.telegram_authorized
        ? payload.telegram_authorized
        : 0,
    }
    const coba = Object.keys(update).forEach(key => {
      if ((update as any)[key] == null || (update as any)[key] == '') {
        delete (update as any)[key]
      }
    })

    const endpoint = new this().privateEndpoint + '/user'

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, update)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateUser(
    id: number,
    payload: any
  ): Promise<ResourceResponse<User>> {
    const endpoint = new this().privateEndpoint + '/user'

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number): Promise<ResourceResponse<User>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listUserCoin(): Promise<ResourceResponse<User[]>> {
    const privateEndpoint =
      new this().privateEndpoint + '/coin-log?is_admin=true'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async search(email: string): Promise<ResourceResponse<User[]>> {
    const privateEndpoint =
      new this().privateEndpoint + '/user?name=' + email + '&email=' + email
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(`${privateEndpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async removePhoto() {
    const privateEndpoint = new this().privateEndpoint + '/me/photo'
    const {
      data: { success },
    } = await Http.delete(privateEndpoint)

    return {
      success,
    }
  }

  public static async requestResetPassword(
    email: string
  ): Promise<ResourceResponse<RequestResetPassword>> {
    const endpoint = new this().endpoint + '/reset-password/request'
    const payload = {
      email: email,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, payload)

    return {
      data,
      success,
    }
  }

  public static async checkResetToken(
    token: string
  ): Promise<ResourceResponse<CheckToken>> {
    const endpoint = new this().endpoint + '/reset-password/check-token'
    const payload = {
      token: token,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, payload)

    return {
      data,
      success,
    }
  }

  public static async resetPassword(
    payload: any
  ): Promise<ResourceResponse<any>> {
    const endpoint = new this().endpoint + '/reset-password'
    const request = {
      token: payload.token,
      password: payload.password,
      confirm_password: payload.passwordConfirmation,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, request)

    return {
      data,
      success,
    }
  }

  public static async changePassword(
    payload: any
  ): Promise<ResourceResponse<any>> {
    const privateEndpoint = new this().privateEndpoint + '/reset-password'
    const request = {
      old_password: payload.password,
      password: payload.newPassword,
      confirm_password: payload.newPasswordConfirm,
    }
    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, request)

    return {
      data,
      success,
    }
  }

  public static async requestVerify(email: any) {
    const endpoint = new this().endpoint + '/verify-email/request'
    const payload = {
      email: email,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async checkVerifyEmail(code: any) {
    const endpoint = new this().endpoint + '/verify-email'
    const payload = {
      code: code,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }
}
