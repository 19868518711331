import { GetterTree } from 'vuex'
import { SidebarDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<SidebarDataInitialState, State> = {
  getUserDataLoading: state => {
    return state.sidebarDataUserLoading
  },
  getUserData: state => {
    return state.sidebarDataUser
  },
  getClassroomDataLoading: state => {
    return state.sidebarDataClassroomLoading
  },
  getClassroomData: state => {
    return state.sidebarDataClassroom
  },
  getClassroomPagination: state => {
    return state.sidebarDataClassroomPagination
  },
}

export default getters
