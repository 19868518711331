import { ActionTree } from 'vuex'
import { NotificationDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GETTING_NOTIFICATION_DATA,
  GET_NOTIFICATION_DATA,
  GET_NOTIFICATION_PAGINATION_DATA,
  GET_UNREAD_NOTIFICATION_DATA,
} from './mutations'

import NotificationModel from '@/models/NotificationModel'

const actions: ActionTree<NotificationDataInitialState, State> = {
  // get notification loading state & data
  fetchNotificationData({ commit }, loadAmount: number) {
    commit(GETTING_NOTIFICATION_DATA, true)
    return new Promise<void>((resolve, reject) => {
      NotificationModel.loadNotificationList(loadAmount)
        .then(response => {
          commit(GET_NOTIFICATION_DATA, response.data)
          commit(GET_NOTIFICATION_PAGINATION_DATA, response.pages)
          commit(GETTING_NOTIFICATION_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // get unread notification loading state & data
  fetchUnreadNotificationData({ commit }) {
    return new Promise<void>((resolve, reject) => {
      NotificationModel.unreadAll()
        .then(response => {
          commit(GET_UNREAD_NOTIFICATION_DATA, response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default actions
