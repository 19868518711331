import { ProfileDataInitialState } from './types'
import { User } from '@/dto'

export const GETTING_PROFILE_DATA = 'GETTING_PROFILE_DATA'
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA'
export const EDIT_PROFILE_DATA_LOADING = 'EDIT_PROFILE_DATA_LOADING'

export default {
  [GETTING_PROFILE_DATA](state: ProfileDataInitialState, values: boolean) {
    state.profileDataUserLoading = values
  },
  [GET_PROFILE_DATA](state: ProfileDataInitialState, values: User) {
    state.profileDataUser = values
  },
  [EDIT_PROFILE_DATA_LOADING](state: ProfileDataInitialState, values: boolean) {
    state.profileEditDataLoading = values
  },
}
