import { GetterTree } from 'vuex'
import { NotificationDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<NotificationDataInitialState, State> = {
  getNotificationDataLoading: state => {
    return state.notificationDataLoading
  },
  getNotificationData: state => {
    return state.notificationData
  },
  getNotificationPagination: state => {
    return state.notificationDataPagination
  },
  getUnReadNotificationData: state => {
    return state.unReadNotificationData
  },
}

export default getters
