<template>
  <a @click="$router.go(-1)" title="Back" style="cursor: pointer;">
    <img src="/assets/images/arrow-back.svg" alt="Back" loading="lazy" />
  </a>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    to: {
      type: String,
      default: '/',
    },
  },
})
</script>
