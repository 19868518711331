import { GetterTree } from 'vuex'
import { MentorInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<MentorInitialState, State> = {
  getPrivateWebinarMentor: state => {
    return state.privateWebinarMentor
  },
}

export default getters
