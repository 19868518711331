import { User } from '@/dto'
import { ProfileDataInitialState } from './types'

const state: ProfileDataInitialState = {
  // profile page state
  profileDataUserLoading: true,
  profileDataUser: {} as User,

  profileEditDataLoading: false,
}

export default state
