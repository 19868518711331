import ResourceResponse from '@/dto/Response/ResourceResponse'
import { UserStatus, ExistingUserData } from '@/dto/ImportDataUser'
import MainApi from '@/utils/Http'
import UserModel from '@/models/UserModel'
import Cookies from 'js-cookie'

const Http = MainApi.getInstance()

type Key = Exclude<keyof ExistingUserData, 'success'>
type UserData = ExistingUserData[Key]
type BulkEndpoint = {
  name: string
  endpoint: string
}

export default class MigrateUserDataModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_USER_MIGRATE}`

  private migrateEndpoints: BulkEndpoint[] = [
    {
      name: 'detail',
      endpoint: process.env.VUE_APP_SERVICE_USER + '/user',
    },
    {
      name: 'transaction',
      endpoint: process.env.VUE_APP_SERVICE_USER_MIGRATE_TRANSACTION,
    },
    {
      name: 'classroom',
      endpoint: process.env.VUE_APP_SERVOCE_USER_MIGRATE_CLASSROOM,
    },
    {
      name: 'subject',
      endpoint: process.env.VUE_APP_SERVICE_USER_MIGRATE_SUBJECT,
    },
    {
      name: 'certificate',
      endpoint: process.env.VUE_APP_SERVICE_USER_MIGRATE_CERTIFICATE,
    },
    {
      name: 'task',
      endpoint: process.env.VUE_APP_SERVICE_USER_MIGRATE_TASK,
    },
    {
      name: 'webinar',
      endpoint: process.env.VUE_APP_SERVICE_USER_MIGRATE_WEBINAR,
    },
  ]

  public static async userStatus(): Promise<ResourceResponse<UserStatus>> {
    const endpoint = new this().endpoint
    const { data } = await Http.get(endpoint)

    return {
      success: data.success,
      errors: [],
      data: data,
    }
  }

  public static async existingData(): Promise<ResourceResponse<any>> {
    const endpoint = new this().endpoint + '/data'
    const { data } = await Http.get(`${endpoint}`)

    return {
      success: data.success,
      errors: [],
      data: data,
    }
  }

  public static async updateStatus(): Promise<
    ResourceResponse<{ success: boolean }>
  > {
    const endpoint = new this().endpoint
    const { data } = await Http.put(`${endpoint}`)

    return {
      success: data.success,
      errors: [],
      data: data,
    }
  }

  public static async postData(
    domain: string,
    existingData: UserData
  ): Promise<ResourceResponse<{ success: boolean }>> {
    // console.log(existingData)
    const endpoint = this.generateBulkEndpoint(domain)
    if (
      endpoint ==
      process.env.VUE_APP_BASE_URL + process.env.VUE_APP_SERVICE_USER + '/user'
    ) {
      const token = Cookies.getJSON('auth')
      const user = ((await UserModel.me(token.token)) as any).data
      const { data, success } = await UserModel.updateUserMigrate(
        user,
        existingData
      )
      return {
        success: success,
        errors: [],
        data: data,
      }
    } else {
      const { data } = await Http.post(`${endpoint}`, existingData)
      return {
        success: data.success,
        errors: [],
        data: data,
      }
    }
  }

  public static generateBulkEndpoint(domain: string): string {
    const getBulkEndpoint = (data: string) => {
      const endpoint = new this().migrateEndpoints.find(
        val => val.name === data
      )
      return endpoint?.endpoint
    }
    const endpoint = getBulkEndpoint(domain)
    return `${process.env.VUE_APP_BASE_URL}` + endpoint
  }
}
