import { ReviewDataInitialState } from './types'
import ClassroomReview from '@/dto/ClassroomReview'
import ClassroomReviewSummary from '@/dto/ClassroomReviewSummary'
import Pagination from '@/dto/Response/Pagination'

export const GET_CLASSROOM_REVIEW = 'GET_CLASSROOM_REVIEW'
export const GET_CLASSROOM_REVIEW_PAGE = 'GET_CLASSROOM_REVIEW_PAGE'
export const GETTING_CLASSROOM_REVIEW_LOADING =
  'GETTING_CLASSROOM_REVIEW_LOADING'

export const GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING =
  'GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING'
export const GET_CLASSROOM_REVIEW_SUMMARY = 'GET_CLASSROOM_REVIEW_SUMMARY'
export default {
  [GET_CLASSROOM_REVIEW](
    state: ReviewDataInitialState,
    values: ClassroomReview[]
  ) {
    state.classroomReviewData = values
  },
  [GET_CLASSROOM_REVIEW_PAGE](
    state: ReviewDataInitialState,
    values: Pagination
  ) {
    state.classroomReviewDataPages = values
  },
  [GETTING_CLASSROOM_REVIEW_LOADING](
    state: ReviewDataInitialState,
    values: boolean
  ) {
    state.classroomReviewLoading = values
  },
  [GET_CLASSROOM_REVIEW_SUMMARY](
    state: ReviewDataInitialState,
    values: ClassroomReviewSummary
  ) {
    state.classroomReviewSummaryData = values
  },
  [GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING](
    state: ReviewDataInitialState,
    values: boolean
  ) {
    state.classroomReviewSummaryLoading = values
  },
}
