import { MediaVideo } from '@/dto/Media'
import Accessory from '@/dto/Accessory'
import { ClassroomDetail, ClassroomItems } from '@/dto/ClassroomV2'
import { ClassroomDataInitialState } from './types'
import Pagination from '@/dto/Response/Pagination'
import PaginationV2 from '@/dto/Response/PaginationV2'
import { CertificateItem } from '@/dto/CertificateV2'
import {
  Classroom,
  ClassroomLevelWebinar,
  PrivateWebinar,
  Subject,
} from '@/dto'

const state: ClassroomDataInitialState = {
  // main classroom page state
  classroomVideoDataLoading: true,
  classroomVideoData: [] as ClassroomItems[],
  classroomVideoDataPages: {} as PaginationV2,

  classroomAccessoryDataLoading: true,
  classroomAccessoryData: [] as Accessory[],
  classroomAccessoryDataPages: {} as Pagination,

  classroomCertificateDataLoading: true,
  classroomCertificateData: [] as CertificateItem[],
  classroomCertificateDataPages: {} as PaginationV2,

  // detail kelas state
  detailClassroomDataLoading: true,
  detailClassroomData: {} as ClassroomDetail,

  // video state
  detailClassroomVideoDataLoading: true,
  detailClassroomVideoData: [] as MediaVideo[],

  // modul state

  // task state

  classroomAllDataLoading: true,
  classroomAllData: [] as Classroom[],

  // Webinar
  classroomWebinarLoading: true,
  classroomWebinarData: [] as ClassroomLevelWebinar[],

  // Webinar Record
  classroomWebinarRecordLoading: true,
  classroomWebinarRecordData: [] as ClassroomLevelWebinar[],

  // Class subject/materi kelas
  classroomSubjectLoading: true,
  classroomSubjectData: [] as Subject[],

  // Private Webinar
  classroomPrivateWebinarData: [] as PrivateWebinar[],

  // Saved Classroom Code
  classroomCode: '',
}

export default state
