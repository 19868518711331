import { CityDataInitialState } from './types'
import ResponseResource from '@/dto/Response/ResourceResponse'
import { City } from '@/dto'

export const GETTING_CITY_DATA = 'GETTING_CITY_DATA'
export const GET_CITY_DATA = 'GET_CITY_DATA'

export default {
  [GETTING_CITY_DATA](state: CityDataInitialState, values: boolean) {
    state.cityDataLoading = values
  },

  [GET_CITY_DATA](
    state: CityDataInitialState,
    values: ResponseResource<City[]>
  ) {
    state.cityDataPayload = values
  },
}
