export default [
  {
    path: '/mentor/',
    name: 'Mentor.Dashboard',
    component: () =>
      import(
        /* webpackChunkName: 'App.Mentor.New.Dashboard' */ '@/main/views/app/Mentor/New/Dashboard.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Jadwal Mentor',
    },
  },
  {
    path: '/mentor/profile',
    name: 'Mentor.Profile',
    component: () =>
      import(
        /* webpackChunkName: 'App.Mentor.New.Dashboard' */ '@/main/views/app/Mentor/New/Profile.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Profile Mentor',
    },
  },
  {
    path: '/mentor/classroom',
    name: 'Mentor.Classroom',
    component: () =>
      import(
        /* webpackChunkName: 'App.Mentor.New.Classroom.List' */ '@/main/views/app/Mentor/New/Classroom/List.vue'
      ),
    meta: {
      requiresAuth: true,
      title: 'Kelas Mentor',
    },
  },
  {
    path: '/mentor/classroom/:classroom_code',
    component: () =>
      import(
        /* webpackChunkName: 'App.Mentor.New.Classroom.Member' */ '@/main/views/app/_Layout/Mentor/ClassroomMain.vue'
      ),
    children: [
      {
        path: '/mentor/classroom/:classroom_code/member',
        name: 'Mentor.Member',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Member' */ '@/main/views/app/Mentor/New/Classroom/Member.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Member Kelas Mentor',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/task',
        name: 'Mentor.Task',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Task' */ '@/main/views/app/Mentor/New/Classroom/Task.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Tugas Kelas Mentor',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/submission/',
        name: 'Mentor.Submission',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Submission' */ '@/main/views/app/Mentor/New/Classroom/Submission.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Tugas Kelas Mentor',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/webinar-record/',
        name: 'Mentor.WebinarRecord',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Webinar.Record' */ '@/main/views/app/Mentor/New/Classroom/WebinarRecord.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Rekaman Webinar',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/ask-mentor/',
        name: 'Mentor.AskMentor',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Ask.Mentor' */ '@/main/views/app/Mentor/New/Classroom/AskMentor.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Tanya Mentor',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/request-webinar/',
        name: 'Mentor.RequestWebinar',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Request.Webinar' */ '@/main/views/app/Mentor/New/Classroom/RequestWebinar.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Request Webinar Mentor',
        },
      },
      {
        path: '/mentor/classroom/:classroom_code/private-webinar/',
        name: 'Mentor.PrivateWebinar',
        component: () =>
          import(
            /* webpackChunkName: 'App.Mentor.New.Classroom.Private.Webinar' */ '@/main/views/app/Mentor/New/Classroom/PrivateWebinar.vue'
          ),
        meta: {
          requiresAuth: true,
          title: 'Private Webinar Mentor',
        },
      },
    ],
  },
]
