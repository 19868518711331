import { SidebarDataInitialState } from './types'
import { User, Classroom } from '@/dto'
import Pagination from '@/dto/Response/Pagination'

export const GETTING_USERSIDEBAR_DATA = 'GETTING_USERSIDEBAR_DATA'
export const GET_USERSIDEBAR_DATA = 'GET_USERSIDEBAR_DATA'
export const GETTING_CLASSROOMSIDEBAR_DATA = 'GETTING_CLASSROOMSIDEBAR_DATA'
export const GET_CLASSROOMSIDEBAR_DATA = 'GET_CLASSROOMSIDEBAR_DATA'
export const GET_CLASSROOMSIDEBAR_PAGINATION_DATA =
  'GET_CLASSROOMSIDEBAR_PAGINATION_DATA'

export default {
  [GETTING_USERSIDEBAR_DATA](state: SidebarDataInitialState, values: boolean) {
    state.sidebarDataUserLoading = values
  },
  [GET_USERSIDEBAR_DATA](state: SidebarDataInitialState, values: User) {
    state.sidebarDataUser = values
  },
  [GETTING_CLASSROOMSIDEBAR_DATA](
    state: SidebarDataInitialState,
    values: boolean
  ) {
    state.sidebarDataClassroomLoading = values
  },
  [GET_CLASSROOMSIDEBAR_DATA](
    state: SidebarDataInitialState,
    values: Classroom[]
  ) {
    state.sidebarDataClassroom = values
  },
  [GET_CLASSROOMSIDEBAR_PAGINATION_DATA](
    state: SidebarDataInitialState,
    values: Pagination
  ) {
    state.sidebarDataClassroomPagination = values
  },
}
