import ResourceResponse from '@/dto/Response/ResourceResponse'
import { City } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class CityModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` + `${process.env.VUE_APP_SERVICE_CITY}`

  public static async list(): Promise<ResourceResponse<City[]>> {
    const endpoint = new this().endpoint + '?per_page=-1'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listOrderName(): Promise<ResourceResponse<City[]>> {
    const endpoint = new this().endpoint + '?per_page=-1&orderBy=name'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }
}
