import { GetterTree } from 'vuex'
import { ReviewDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<ReviewDataInitialState, State> = {
  getClassroomReviewLoading: state => {
    return state.classroomReviewLoading
  },
  getClassroomReviewData: state => {
    return state.classroomReviewData
  },
  getClassroomReviewDataPages: state => {
    return state.classroomReviewDataPages
  },
  getClassroomReviewSummaryLoading: state => {
    return state.classroomReviewSummaryLoading
  },
  getClassroomReviewSummaryData: state => {
    return state.classroomReviewSummaryData
  },
}

export default getters
