import { ActionTree } from 'vuex'
import Cookies from 'js-cookie'
import { ProfileDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GETTING_PROFILE_DATA,
  GET_PROFILE_DATA,
  EDIT_PROFILE_DATA_LOADING,
} from './mutations'

import UserModel from '@/models/UserModel'

const AuthObj = Cookies.getJSON('auth')

const actions: ActionTree<ProfileDataInitialState, State> = {
  fetchProfileData({ commit }) {
    commit(GETTING_PROFILE_DATA, true)
    return new Promise<void>((resolve, reject) => {
      UserModel.me(AuthObj.token)
        .then((response: any) => {
          // console.log(response)
          commit(GET_PROFILE_DATA, response.data)
          commit(GETTING_PROFILE_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  fetchEditProfileData(
    { commit },
    { firstName, whatsapp, instagram, address, date, cityId, city, birthPlace }
  ) {
    commit(EDIT_PROFILE_DATA_LOADING, true)
    return new Promise<void>((resolve, reject) => {
      try {
        UserModel.editProfile(
          firstName,
          whatsapp,
          instagram,
          address,
          date,
          cityId,
          city,
          birthPlace
        )
          .then(() => {
            commit(EDIT_PROFILE_DATA_LOADING, false)
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      } catch (error) {
        reject(error)
      }
    })
  },
}

export default actions
