import ResourceResponse from '@/dto/Response/ResourceResponse'
import { VoucherClassroom } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class VoucherClassroomModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_VOUCHER}` +
    '/voucher-classroom'

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_VOUCHER_PRIVATE}` +
    '/voucher-classroom'

  public static async list(): Promise<ResourceResponse<VoucherClassroom[]>> {
    const endpoint = new this().endpoint + '/show'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listRedeem(code: string) {
    const endpoint = new this().endpoint + '/get-redeem'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${code}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(
    payload: VoucherClassroom
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().endpoint + '/add'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(
    id: number
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/show/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: VoucherClassroom
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/update/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/delete/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async redeem(
    code: string
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().privateEndpoint + '/redeem'

    const {
      data: { data, message, success },
    } = await Http.put(`${endpoint}/${code}`)

    return {
      success,
      errors: [],
      data,
      message,
    }
  }

  public static async checkVoucher(
    code: string
  ): Promise<ResourceResponse<VoucherClassroom>> {
    const endpoint = new this().endpoint + '/get-redeem'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${code}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
