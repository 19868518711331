export default [
  {
    path: '/login',
    name: 'Login',
    redirect: { name: 'Register' },
    meta: {
      guestOnly: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () =>
      import(
        /* webpackChunkName: 'App.LoginRegister' */ '@/main/views/app/LoginRegisterNew.vue'
      ),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () =>
      import(
        /* webpackChunkName: 'App.ForgotPassword' */ '@/main/views/app/ForgotPassword.vue'
      ),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () =>
      import(
        /* webpackChunkName: 'App.ResetPassword' */ '@/main/views/app/ResetPassword.vue'
      ),
    meta: {
      guestOnly: true,
    },
  },
  {
    path: '/process-data',
    name: 'ProcessData',
    component: () =>
      import(
        /* webpackChunkName: 'App.ForgotPassword' */ '@/main/views/app/ProcessData.vue'
      ),
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () =>
      import(
        /* webpackChunkName: 'App.VerifyEmail' */ '@/main/views/app/VerifyEmail.vue'
      ),
    meta: {
      guestOnly: true,
    },
  },
]
