/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import MainApi from '@/utils/Http'
import moment from 'moment'
const Http = MainApi.getInstance()

import {
  Classroom,
  ClassroomLevelWebinar,
  ClassroomLevelTask,
  Quiz,
  Subject,
  ClassroomLevel,
  FlashSaleItem,
  LandingPage,
  MyWebinar,
  ClassroomMe,
} from '@/dto'
import Cookies from 'js-cookie'
import ResourceResponseV2 from '@/dto/Response/ResourceResponseV2'
import ClassroomV2 from '@/dto/ClassroomV2'
import CertificateV2 from '@/dto/CertificateV2'
import WebinarQuiz from '@/dto/WebinarQuiz'
import ClassroomReview from '@/dto/ClassroomReview'
import ClassroomReviewSummary from '@/dto/ClassroomReviewSummary'

export default class ClassroomModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM}`

  protected endpointV2 =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_V2}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE}`

  protected privateEndpointV2 =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE_V2}`

  protected privateEndpointV3 =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE_V3}`

  public static async list(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?is_front_page=1'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listOneRupiah(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listFrontClass(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listPromo(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?is_promotion=1'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listPrakerja(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?code=A'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listDetail(
    id: string | string[]
  ): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint + '/classroom?code=' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listMe() {
    const endpoint = new this().privateEndpoint + '/me'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async loadMyClassroomList(
    page = 1,
    showPerPage = 10
  ): Promise<ResourceResponseV2<ClassroomV2>> {
    const endpoint =
      new this().privateEndpointV2 + `/me?page=${page}&per_page=${showPerPage}`
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
    }
  }

  public static async loadMyClassroomListV3(): Promise<
    ResourceResponse<ClassroomMe>
  > {
    const endpoint = new this().privateEndpointV3 + `/me`
    const {
      data: { data, pages, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
      pages,
    }
  }

  public static async searchMyClassroomByName(
    page = 1,
    showPerPage = 10,
    term = ''
  ): Promise<ResourceResponseV2<ClassroomV2>> {
    const endpoint =
      new this().privateEndpointV2 +
      `/me?page=${page}&per_page=${showPerPage}&search=${term}`
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
    }
  }

  public static async loadClassroomList(
    showPerPage = 10
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint =
      new this().privateEndpoint + `/classroom?per_page=${showPerPage}`

    const {
      data: { data, success, pages },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listAll() {
    const endpoint = new this().endpoint + '/classroom?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listAllPrivate() {
    const endpoint = new this().privateEndpoint + '/classroom?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async searchListAll(code: string) {
    const endpoint = new this().privateEndpoint + '/classroom'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}?code=${code}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async HistoryClassroom(uuid: string) {
    const privateEndpoint = new this().privateEndpoint + '/user/' + uuid

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listMyWebinar(): Promise<ResourceResponse<MyWebinar[]>> {
    const endpoint =
      new this().privateEndpointV2 + '/me/webinar?page=1&per_page=-1'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listWebinarByClassLevel(
    levelId: string
  ): Promise<ResourceResponse<ClassroomLevelWebinar[]>> {
    const endpoint = new this().privateEndpoint + '/level'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelId}/webinar`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listWebinarRecordByClassLevel(
    levelId: number
  ): Promise<ResourceResponse<ClassroomLevelWebinar[]>> {
    const endpoint = new this().privateEndpoint + '/level'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelId}/webinar-record`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listWebinarRecordByClassLevelCode(
    levelCode: string
  ): Promise<ResourceResponse<ClassroomLevelWebinar[]>> {
    const endpoint = new this().privateEndpoint + '/level'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelCode}/webinar-record`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async viewWebinarRecord(id: number, uuid: string) {
    const privateEndpoint =
      new this().privateEndpoint + '/webinar-record/' + id + '/user'

    const payload = [
      {
        uuid: uuid,
      },
    ]
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailWebinarRecordByID(
    levelId: number
  ): Promise<ResourceResponse<ClassroomLevelWebinar[]>> {
    const endpoint = new this().privateEndpoint + '/webinar-record'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelId}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listMyQuiz(): Promise<
    ResourceResponse<ClassroomLevelTask[]>
  > {
    const endpoint = new this().privateEndpoint + '/quiz'
    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listSubject(
    levelId: any
  ): Promise<ResourceResponse<Subject[]>> {
    const endpoint = new this().privateEndpoint + '/level'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelId}/subject`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listModul(
    codeClass: any
  ): Promise<ResourceResponse<any>> {
    const endpoint = new this().privateEndpoint + '/classroom'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${codeClass}/module`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailModul(
    moduleId: number
  ): Promise<ResourceResponse<any>> {
    const endpoint = new this().privateEndpoint + '/module'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${moduleId}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async RatingModul(id: number, rating: number) {
    const privateEndpoint =
      new this().privateEndpoint + '/module/' + id + '/rating'
    const payload = {
      rating: rating,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async ViewModul(id: number) {
    const privateEndpoint =
      new this().privateEndpoint + '/module/' + id + '/view'
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listAllPromotion() {
    const endpoint = new this().privateEndpoint + '/promotion'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async listPromotion(id: number) {
    const endpoint = new this().privateEndpoint + '/promotion/' + id
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async listAllLanding(): Promise<
    ResourceResponse<LandingPage[]>
  > {
    const endpoint = new this().endpoint + '/landing-page'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async listLanding(id: number) {
    const endpoint = new this().privateEndpoint + '/landing-page/' + id
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async listAllSlider() {
    const endpoint = new this().privateEndpoint + '/slider-promotion'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async listSlider(id: number) {
    const endpoint = new this().privateEndpoint + '/slider-promotion/' + id
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
      data,
    }
  }

  public static async detailSubject(
    subjectId: any
  ): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().privateEndpoint + '/subject'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${subjectId}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(
    payload: Classroom
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addToClass(
    code: string
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().privateEndpoint + '/level'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}/${code}/user`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addPromotion(payload: any) {
    let endpoint = new this().privateEndpoint + '/promotion'
    console.log(payload)
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('product_code', payload.productCode)
    formData.append('content', payload.content)
    formData.append('price', payload.price)
    formData.append('fake_price', payload.fakePrice)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('promotion_caption', payload.promotionCaption)
    formData.append('promotion_text', payload.promotionText)
    formData.append('name_bump', payload.bumpName)
    formData.append('product_code_bump', payload.bumpProductCode)
    formData.append('fake_price_bump', payload.bumpFakePrice)
    formData.append('price_bump', payload.bumpPrice)
    if (payload.bump) {
      endpoint = endpoint + '?is_bump=true'
    } else {
      endpoint = endpoint + '?is_bump=false'
    }
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addLanding(payload: any) {
    const endpoint = new this().privateEndpoint + '/landing-page'

    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('url', payload.url)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('icon', payload.icon)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('is_active', payload.isActive === true ? '1' : '0')
    formData.append('price', payload.price)
    formData.append('discount', payload.discount)

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addSlider(id: any, payload: any) {
    const endpoint = new this().privateEndpoint + '/slider-promotion'

    const formData = new FormData()
    formData.append('promotion_id', id)
    formData.append('image', payload)

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: any): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().endpoint + '/classroom'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailByClassroomCode(
    levelCode: string
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().privateEndpoint + '/classroom'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelCode}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailClassroomLevel(
    levelCode: string
  ): Promise<ResourceResponse<ClassroomLevel>> {
    const endpoint = new this().privateEndpoint + '/level'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${levelCode}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async GiveReviewClassroomLevel(
    levelCode: string,
    payload: any
  ) {
    const endpoint = new this().privateEndpoint + '/level'

    payload.rating = parseInt(payload.rating)
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${levelCode}/rating`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async GiveReviewClassroomLevelV2(
    levelCode: string,
    payload: any
  ) {
    const formData = new FormData()
    formData.append('rating', payload.rating)
    formData.append('content', payload.content)
    if (payload.imageRating) {
      formData.append('image', payload.imageRating)
    }
    const endpoint = new this().privateEndpointV2 + '/level'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}/${levelCode}/rating`, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailPromotion(id: number) {
    const endpoint = new this().privateEndpoint + '/promotion'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Classroom
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updatePromotion(id: number, payload: any) {
    let endpoint = new this().privateEndpoint + '/promotion'

    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('product_code', payload.productCode)
    formData.append('content', payload.content)
    formData.append('price', payload.price)
    formData.append('fake_price', payload.fakePrice)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('promotion_caption', payload.promotionCaption)
    formData.append('promotion_text', payload.promotionText)
    formData.append('name_bump', payload.bumpName)
    formData.append('product_code_bump', payload.bumpProductCode)
    formData.append('fake_price_bump', payload.bumpFakePrice)
    formData.append('price_bump', payload.bumpPrice)
    if (payload.bump) {
      endpoint = endpoint + '?is_bump=true'
    } else {
      endpoint = endpoint + '?is_bump=false'
    }

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateLanding(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/landing-page'

    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('url', payload.url)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('icon', payload.icon)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('is_active', payload.isActive === true ? '1' : '0')
    formData.append('price', payload.price)
    formData.append('discount', payload.discount)

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<Classroom>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroyPromotion(id: number) {
    const endpoint = new this().privateEndpoint + '/promotion'

    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroyLanding(id: number) {
    const endpoint = new this().privateEndpoint + '/landing-page'

    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroySlider(id: number) {
    const endpoint = new this().privateEndpoint + '/slider-promotion'

    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listQuiz(
    id: string | number
  ): Promise<ResourceResponse<Quiz[]>> {
    const privateEndpoint =
      new this().privateEndpoint + '/level/' + id + '/quiz'
    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async SubmitTask(id: number, image: any) {
    const privateEndpoint = new this().privateEndpoint + '/task/' + id

    const token = Cookies.getJSON('auth')

    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const formData = new FormData()
    formData.append('file', image)

    await Http.post(`${privateEndpoint}`, formData, { headers })
      .then(res => {
        return res.data
      })
      .catch(err => {
        return err.response
      })
  }

  public static async getTaskClassroom(levelId: any) {
    const privateEndpoint =
      new this().privateEndpoint + '/level/' + levelId + '/task'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async RatingClass(id: number, rating: number, isView: boolean) {
    const privateEndpoint =
      new this().privateEndpoint + '/subject/' + id + '/rating'
    const payload = {
      rating: rating,
      is_viewed: isView,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async ViewSubject(id: any) {
    const privateEndpoint =
      new this().privateEndpoint + '/subject/' + id + '/view'
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listClassroomAdmin() {
    const privateEndpoint =
      new this().privateEndpoint + '/classroom?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneClassroomAdmin(
    id: string
  ): Promise<ResourceResponse<Classroom[]>> {
    const privateEndpoint = new this().privateEndpoint + '/classroom/' + id

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addClassroom(payload: any) {
    console.log(payload)
    const privateEndpoint = new this().privateEndpoint + '/classroom'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('code', payload.code)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('icon', payload.icon)
    formData.append('header', payload.header)
    formData.append('category_id', payload.categoryId)
    formData.append('forum', payload.forum)
    formData.append('begin_forum', payload.beginForum)
    formData.append('end_forum', payload.endForum)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('rating', payload.rating)
    formData.append('coin_price', payload.coinPrice)
    formData.append('rupiah_price', payload.rupiahPrice)
    formData.append('min_purchase', payload.minPurchase)
    formData.append('min_discount', payload.minDiscount)
    formData.append('started_at', payload.startedAt)
    formData.append('whatsapp_link', payload.whatsappLink)
    formData.append('is_express', payload.isExpress)
    formData.append('is_front_page', payload.isFrontpage)
    formData.append('is_private', payload.isPrivate)
    formData.append('is_active', payload.isActive)
    formData.append('must_voucher', payload.mustVoucher)

    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateClassroom(id: string, payload: any) {
    console.log(payload)
    const privateEndpoint = new this().privateEndpoint + '/classroom/' + id
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('code', payload.code)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('icon', payload.icon)
    formData.append('header', payload.header)
    formData.append('category_id', payload.categoryId)
    formData.append('forum', payload.forum)
    formData.append('begin_forum', payload.beginForum)
    formData.append('end_forum', payload.endForum)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('rating', payload.rating)
    formData.append('coin_price', payload.coinPrice)
    formData.append('rupiah_price', payload.rupiahPrice)
    formData.append('min_purchase', payload.minPurchase)
    formData.append('min_discount', payload.minDiscount)
    formData.append('started_at', payload.startedAt)
    console.log('ini dari model', payload.startedAt)
    formData.append('whatsapp_link', payload.whatsappLink)
    formData.append('is_express', payload.isExpress)
    formData.append('is_front_page', payload.isFrontpage)
    formData.append('is_private', payload.isPrivate)
    formData.append('is_active', payload.isActive)
    formData.append('must_voucher', payload.mustVoucher)
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async MemberClassroom(id: number) {
    const privateEndpoint =
      new this().privateEndpoint + '/classroom/' + id + '/user'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listLevelAdmin() {
    const privateEndpoint = new this().privateEndpoint + '/level?per_page=10000'

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success, pages },
    } = await Http.get(privateEndpoint, { headers })

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async getOneClassroomLevelAdmin(idLevel: string) {
    const privateEndpoint = new this().privateEndpoint + '/level/' + idLevel

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint, { headers })
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addClassroomLevel(payload: any) {
    const privateEndpoint = new this().privateEndpoint + '/level'
    const formData = new FormData()
    formData.append('class_code', payload.classCode)
    formData.append('code', payload.code)
    formData.append('ordering', payload.ordering)
    formData.append('name', payload.name)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('header', payload.header)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('coin_price', payload.coinPrice)
    formData.append('rupiah_price', payload.rupiahPrice)
    formData.append('wholesale_price', payload.wholesalePrice)
    formData.append('rupiah_wholesale', payload.rupiahWholesale)
    formData.append('classroom_id', payload.classroomId)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, formData, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateClassroomLevel(id: string, payload: any) {
    const privateEndpoint = new this().privateEndpoint + '/level/' + id
    const formData = new FormData()
    formData.append('class_code', payload.classCode)
    formData.append('code', payload.code)
    formData.append('ordering', payload.ordering)
    formData.append('name', payload.name)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('header', payload.header)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('coin_price', payload.coinPrice)
    formData.append('rupiah_price', payload.rupiahPrice)
    formData.append('wholesale_price', payload.wholesalePrice)
    formData.append('rupiah_wholesale', payload.rupiahWholesale)
    formData.append('classroom_id', payload.classroomId)
    console.log(payload)
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
      'Content-Type': 'multipart/form-data',
    }

    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, formData, { headers })
    console.log(data)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listLevelPage(
    perPage: number,
    page: number,
    search: string
  ): Promise<ResourceResponse<Classroom[]>> {
    let endpoint = new this().endpoint

    if (search) {
      endpoint =
        endpoint +
        '/level?per_page=' +
        perPage +
        '&page=' +
        page +
        '&search=' +
        search
    } else {
      endpoint = endpoint + '/level?per_page=' + perPage + '&page=' + page
    }

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listLevelAll(): Promise<ResourceResponse<Classroom[]>> {
    const endpoint = new this().endpoint

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}/level?per_page=1000`, { headers })

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async MemberClassroomLevel(id: number) {
    const privateEndpoint =
      new this().privateEndpoint + '/level/' + id + '/user'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async assignMentor(uuid: string, classCode: string) {
    const endpoint =
      new this().privateEndpoint + '/level/' + classCode + '/mentor'
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, uuid, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async unassignMentor(uuid: string, classCode: string) {
    const endpoint =
      new this().privateEndpoint + '/level/' + classCode + '/mentor/' + uuid
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    console.log(headers)
    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}`, { headers })

    return {
      data,
      success,
      errors: [],
    }
  }

  public static async listTaskAdmin(
    id: number
  ): Promise<ResourceResponse<ClassroomLevelTask[]>> {
    const endpoint = new this().privateEndpoint + '/level/' + id + '/task'

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneTask(
    id: any
  ): Promise<ResourceResponse<ClassroomLevelTask>> {
    const endpoint = new this().privateEndpoint + '/task/' + id

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async createTask(
    levelId: any,
    payload: any
  ): Promise<ResourceResponse<ClassroomLevelTask>> {
    const endpoint = new this().privateEndpoint + '/level/' + levelId + '/task/'

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.post(endpoint, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateTask(
    taskId: any,
    payload: any
  ): Promise<ResourceResponse<ClassroomLevelTask>> {
    const endpoint = new this().privateEndpoint + '/task/' + taskId

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async downloadCertificate(url: string) {
    return await Http.downloadFile(url)
  }

  public static async getMentorWebinar(start: string, end: string) {
    const endpoint =
      new this().privateEndpoint +
      '/mentor/webinar?started_at=' +
      start +
      '&finished_at=' +
      end

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async scoringTask(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/user-submission/' + id

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneUserSubmission(id: number) {
    const endpoint = new this().privateEndpoint + '/user-submission/' + id

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getMentorVideo(code: string, search: string) {
    let endpoint = new this().privateEndpoint

    if (search) {
      endpoint = endpoint + '/mentor/video?code=' + code + '&search=' + search
    } else {
      endpoint = endpoint + '/mentor/video?code=' + code
    }

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getMentorModule(code: string, search: string) {
    let endpoint = new this().privateEndpoint

    if (search) {
      endpoint = endpoint + '/mentor/module?code=' + code + '&search=' + search
    } else {
      endpoint = endpoint + '/mentor/module?code=' + code
    }

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getMentorClass() {
    const endpoint = new this().privateEndpoint + '/mentor/classroom'

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getMentorClassroomMember(
    code: any,
    search: any,
    perPage: number,
    page: number
  ) {
    let endpoint = new this().privateEndpoint
    if (!search) {
      endpoint =
        endpoint +
        '/mentor/member?code=' +
        code +
        '&per_page=' +
        perPage +
        '&page=' +
        page
    } else {
      endpoint =
        endpoint +
        '/mentor/member?code=' +
        code +
        '&per_page=' +
        perPage +
        '&page=' +
        page +
        '&search=' +
        search
    }

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async getMemberSubmission(id: number) {
    const endpoint = new this().privateEndpoint + '/user-submission/' + id

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async scoringMemberSubmission(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/user-submission/' + id

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async rejectMemberSubmission(id: number) {
    const endpoint =
      new this().privateEndpoint + '/user-submission/' + id + '/reject'

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async exportVoucherReporting(payload: any) {
    const endpoint = new this().privateEndpoint + '/reporting/partner'
    return await Http.get(
      `${endpoint}?store=${payload.commerce.store}&start_date=${payload.startDate}&end_date=${payload.endDate}`,
      { responseType: 'blob' }
    )
  }

  public static async getVoucherPass(commerce: string, classCode: string) {
    const endpoint = new this().privateEndpoint + '/reporting'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}?store=${commerce}&class=${classCode}`)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getListFlashsale() {
    const endpoint = new this().privateEndpoint + '/flashsale'
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getDetailFlashsale(id: number) {
    const endpoint = new this().privateEndpoint + '/flashsale/' + id
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async createFlashsale(payload: any) {
    const endpoint = new this().privateEndpoint + '/flashsale'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append(
      'started_at',
      `"${moment(payload.startAt).format('YYYY-MM-DD HH:mm:ss')}"`
    )
    formData.append(
      'expired_at',
      `"${moment(payload.expireAt).format('YYYY-MM-DD HH:mm:ss')}"`
    )
    formData.append('is_active', payload.isActive ? '1' : '0')
    console.log(
      'Sss',
      typeof moment(payload.startedAt).format('YYYY-MM-DD HH:mm:ss')
    )

    const {
      data: { data, success },
    } = await Http.post(endpoint, formData)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateFlashsale(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/flashsale/' + id
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append(
      'started_at',
      `"${moment(payload.startAt).format('YYYY-MM-DD HH:mm:ss')}"`
    )
    formData.append(
      'expired_at',
      `"${moment(payload.expireAt).format('YYYY-MM-DD HH:mm:ss')}"`
    )
    formData.append('is_active', payload.isActive ? '1' : '0')

    const {
      data: { data, success },
    } = await Http.put(endpoint, formData)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getListFlashsaleItem(): Promise<
    ResourceResponse<FlashSaleItem[]>
  > {
    const endpoint = new this().privateEndpoint + '/flashsale-item'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getDetailFlashsaleItem(
    id: number
  ): Promise<ResourceResponse<FlashSaleItem[]>> {
    const endpoint = new this().privateEndpoint + '/flashsale-item/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async createFlashsaleItem(payload: any) {
    const endpoint = new this().privateEndpoint + '/flashsale-item'

    const body = {
      flash_sale_id: Number(payload.flashsaleId),
      code: payload.code,
      percentage: Number(payload.percentage),
      rupiah_price: Number(payload.rupiahPrice),
      coin_price: Number(payload.coinPrice),
      description: payload.description,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateFlashsaleItem(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/flashsale-item/' + id

    const body = {
      flash_sale_id: Number(payload.flashsaleId),
      code: payload.code,
      percentage: Number(payload.percentage),
      rupiah_price: Number(payload.rupiahPrice),
      coin_price: Number(payload.coinPrice),
      description: payload.description,
    }

    const {
      data: { data, success },
    } = await Http.put(endpoint, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getListFreeClassroom() {
    const endpoint = new this().privateEndpoint + '/free/'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneFreeClassroom(id: number) {
    const endpoint = new this().privateEndpoint + '/free/' + id

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addFreeClassroom(payload: any) {
    const endpoint = new this().privateEndpoint + '/free'

    const body = {
      classroom_id: Number(payload.classroomId),
      started_at: payload.startedAt,
      expired_at: payload.expiredAt,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateFreeClassroom(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/free/' + id

    const body = {
      classroom_id: Number(payload.classroomId),
      started_at: payload.startedAt,
      expired_at: payload.expiredAt,
    }

    const {
      data: { data, success },
    } = await Http.put(endpoint, body)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async deleteFreeClassroom(id: number) {
    const endpoint = new this().privateEndpoint + '/free'

    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async assignFreeClassroom(id: number) {
    const endpoint = new this().privateEndpoint + '/assign/user/' + id

    const {
      data: { data, success },
    } = await Http.post(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async assignFreeClassroomNewUser(token: any) {
    const endpoint = new this().privateEndpoint + '/assign/free/'
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.post(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getCertificate(code: any) {
    const endpoint =
      new this().privateEndpoint + '/certificate/' + code + '/show'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getCertificateV2(code: any) {
    const endpoint =
      new this().privateEndpointV2 + '/certificate/' + code + '/show'

    const headers = {
      responseType: 'arraybuffer',
    }

    const {
      data: { data, success },
    } = await Http.get(endpoint, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async joinWebinar(id: number) {
    const endpoint =
      new this().privateEndpoint + '/webinar/' + id + '?join=true'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listCertificateNew() {
    const endpoint =
      'https://api.sekolahdesain.id/classroom/private/api/v2/classroom/certificate'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getCertificateNew(uuid: any) {
    const endpoint =
      'https://api.sekolahdesain.id/classroom/private/api/v2/classroom/certificate?search=' +
      uuid

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async loadMyClassroomCertificateListV2(
    page = 1,
    showPerPage = 10
  ): Promise<ResourceResponseV2<CertificateV2>> {
    const endpoint =
      new this().privateEndpointV2 +
      `/me/certificate?page=${page}&per_page=${showPerPage}`

    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      data,
    }
  }

  public static async listWebinarQuiz(
    id: any
  ): Promise<ResourceResponse<WebinarQuiz[]>> {
    const endpoint =
      new this().privateEndpoint + '/level/' + id + '/webinar-quiz'

    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      data,
      success,
    }
  }

  public static async listSubjectPublic(code: string) {
    const endpoint = new this().endpoint + '/classroom/' + code + '/video'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
      errors: [],
    }
  }

  public static async listClassroomReview(
    id: number,
    rating: number,
    perPage: number
  ): Promise<ResourceResponse<ClassroomReview[]>> {
    let endpoint =
      new this().endpoint + '/classroom/' + id + '/review?per_page=' + perPage
    if (rating != 0) {
      endpoint = endpoint + '&rating=' + rating
    }
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
      errors: [],
    }
  }

  public static async listClassroomReviewSummary(
    id: number
  ): Promise<ResourceResponse<ClassroomReviewSummary[]>> {
    const endpoint =
      new this().endpoint + '/classroom/' + id + '/review/summary'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
      errors: [],
    }
  }

  public static async getFacilities(code: string) {
    const endpoint = new this().endpoint + '/classroom/' + code + '/facility'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      errors: [],
    }
  }

  public static async getHighlight() {
    const endpoint = new this().endpoint + '/tag/hot'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getHighlightPrivate() {
    const endpoint = new this().privateEndpoint + '/tag/hot'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getPreTest(code: string) {
    const endpoint =
      new this().privateEndpoint + '/classroom/' + code + '/pre-test'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getPostTest(code: string) {
    const endpoint =
      new this().privateEndpoint + '/classroom/' + code + '/post-test'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async submitPrePostTest(
    code: string,
    type: number,
    payload: any
  ) {
    let endpoint = new this().privateEndpoint + '/classroom/' + code
    if (type == 1) {
      endpoint = endpoint + '/post-test'
    } else {
      endpoint = endpoint + '/pre-test'
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      data,
      errors: [],
    }
  }

  public static async completionCertificate(code: string) {
    const endpoint =
      new this().privateEndpoint + '/completion/' + code + '/show'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async graduationCertificate(code: string) {
    const endpoint =
      new this().privateEndpoint + '/graduation/' + code + '/show'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getRequestWebinarPlus(code: string) {
    const endpoint =
      new this().privateEndpoint +
      '/mentor/request-webinar-plus?classroom=' +
      code

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getUserRequestWebinarPlus(webinarId: number) {
    const endpoint =
      new this().privateEndpoint + '/mentor/webinar/' + webinarId + '/request'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async approveRequestWebinar(webinarId: number) {
    const endpoint =
      new this().privateEndpoint + '/mentor/webinar/' + webinarId + '/approve'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async rejectRequestWebinar(webinarId: number) {
    const endpoint =
      new this().privateEndpoint + '/mentor/webinar/' + webinarId + '/reject'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async requestWebinarPlusMentor(payload: any) {
    const endpoint = new this().privateEndpoint + '/mentor/webinar'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      data,
    }
  }

  public static async requestWebinarPlusUser(webinarId: number, payload: any) {
    const endpoint =
      new this().privateEndpoint + '/webinar/' + webinarId + '/webinar-plus'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      data,
    }
  }

  public static async getMaterials(code: string) {
    const endpoint =
      new this().privateEndpoint + '/classroom/' + code + '/materials'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async addQuestion(code: string, payload: any) {
    const endpoint =
      new this().privateEndpoint + '/classroom/' + code + '/question'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      data,
    }
  }

  public static async listQuestion(code: string) {
    const endpoint =
      new this().privateEndpoint + '/classroom/' + code + '/question'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async createWebinarRecordMentor(code: string, payload: any) {
    const endpoint =
      new this().privateEndpoint +
      '/mentor/classroom/' +
      code +
      '/webinar-record'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      data,
    }
  }

  public static async updateWebinarRecordMentor(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/mentor/webinar-record/' + id
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, payload)

    return {
      success,
      data,
    }
  }

  public static async deleteWebinarRecordMentor(id: number) {
    const endpoint = new this().privateEndpoint + '/mentor/webinar-record/' + id
    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async getUserQuestionMentor(code: string, query: any) {
    const endpoint =
      new this().privateEndpoint +
      '/mentor/classroom/' +
      code +
      '/question?limit=' +
      query.perPage +
      '&page=' +
      query.page
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      data,
    }
  }

  public static async getMentorTaskList(code: string) {
    const endpoint =
      new this().privateEndpoint + '/mentor/classroom/' + code + '/task'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      pages,
      data,
    }
  }

  public static async createMentorTask(code: string, payload: any) {
    const endpoint =
      new this().privateEndpoint + '/mentor/classroom/' + code + '/task'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      data,
      success,
    }
  }

  public static async updateMentorTask(id: number, payload: any) {
    const endpoint = new this().privateEndpoint + '/mentor/task/' + id
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, payload)

    return {
      data,
      success,
    }
  }

  public static async deleteMentorTask(id: number) {
    const endpoint = new this().privateEndpoint + '/mentor/task/' + id
    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}`)

    return {
      data,
      success,
    }
  }

  public static async recommendationPublic(code: string) {
    const endpoint =
      new this().endpoint + '/recommendation/' + code + '?all=true'
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
    }
  }

  public static async newsList(page: number, perPage: number) {
    const endpoint =
      new this().endpoint + '/news?page=' + page + '&per_page=' + perPage
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
    }
  }

  public static async detailPublicV2(code: string) {
    const endpoint = new this().endpointV2 + '/classroom/' + code
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async detailPrivateV2(code: string) {
    const endpoint = new this().privateEndpointV2 + '/classroom/' + code
    const {
      data: { data, success },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async classroomReview(
    code: string,
    page: number,
    perPage: number
  ) {
    const endpoint =
      new this().endpointV2 +
      '/classroom/' +
      code +
      '/review?page=' +
      page +
      '&per_page=' +
      perPage
    const {
      data: { data, success, pages, rating },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
      pages,
      rating,
    }
  }

  public static async listCertificateV3() {
    const endpoint = new this().privateEndpointV3 + '/certificate/'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      success,
      data,
      pages,
    }
  }

  public static async searching(
    search: string,
    page: number,
    perPage: number,
    type: string
  ) {
    let endpoint =
      new this().privateEndpoint +
      '/search?search=' +
      search +
      '&page=' +
      page +
      '&per_page=' +
      perPage
    if (type) {
      endpoint = endpoint + '&type=' + type
    }
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)
    return {
      success,
      data,
      pages,
    }
  }

  public static async getListPrivateWebinar(classCode: string) {
    const endpoint =
      new this().privateEndpoint +
      '/classroom/' +
      classCode +
      '/webinar-private'
    const {
      data: { data, success, pages },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
    }
  }

  public static async addPrivateWebinar(classCode: string, payload: any) {
    const endpoint =
      new this().privateEndpoint +
      '/classroom/' +
      classCode +
      '/webinar-private'
    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      data,
      success,
    }
  }

  public static async reapplyPrivateWebinar(
    privateWebinarId: number,
    payload: any
  ) {
    const endpoint =
      new this().privateEndpoint +
      '/webinar-private/' +
      privateWebinarId +
      '/reschedule'
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, payload)

    return {
      data,
      success,
    }
  }

  public static async getListPrivateWebinarMentor(classCode: string) {
    const endpoint =
      new this().privateEndpoint + '/mentor/webinar-private/' + classCode
    const {
      data: { data, pages, success },
    } = await Http.get(`${endpoint}`)

    return {
      data,
      success,
      pages,
    }
  }

  public static async acceptPrivateWebinar(id: number) {
    const endpoint =
      new this().privateEndpoint + '/webinar-private/' + id + '/accept'
    const {
      data: { success, data },
    } = await Http.put(`${endpoint}`)

    return {
      success,
      data,
    }
  }

  public static async rejectPrivateWebinar(id: number, payload: any) {
    const endpoint =
      new this().privateEndpoint + '/webinar-private/' + id + '/reject'
    const {
      data: { status },
    } = await Http.put(`${endpoint}`, payload)

    return {
      status,
    }
  }
}
