import { ActionTree } from 'vuex'
import { MentorInitialState } from './types'
import { State } from '@/main/store'

import { GET_PRIVATE_WEBINAR_MENTOR } from './mutations'

import ClassroomModel from '@/models/ClassroomModel'

const actions: ActionTree<MentorInitialState, State> = {
  fetchPrivateWebinarList({ commit }, classCode) {
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.getListPrivateWebinarMentor(classCode)
        .then(response => {
          commit(GET_PRIVATE_WEBINAR_MENTOR, response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default actions
