import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Subject } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
import Cookies from 'js-cookie'

export default class SubjectModel {
  protected endpoint = `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE}`

  public static async list(): Promise<ResourceResponse<Subject[]>> {
    const endpoint = new this().endpoint + '/subject'

    const {
      data: { data, pages, success },
    } = await Http.get(`${endpoint}?per_page=1000`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listByClass(
    classroomId: any
  ): Promise<ResourceResponse<Subject[]>> {
    const endpoint =
      new this().endpoint + '/classroom/' + classroomId + '/video'

    const {
      data: { data, pages, success },
    } = await Http.get(`${endpoint}?per_page=1000`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listModule() {
    const endpoint = new this().endpoint + '/module'

    const {
      data: { data, pages, success },
    } = await Http.get(`${endpoint}?per_page=100`)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async add(payload: any): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().endpoint + '/subject'

    const formData = new FormData()
    formData.append('title', payload.title)
    formData.append('slug', payload.slug)
    formData.append('classroom_id', payload.classroomId.id)
    formData.append('classroom_level_id', payload.classroomLevelId.id)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('video', payload.video)
    formData.append('video_link', payload.link)
    formData.append('published_at', `"` + payload.publishedAt + `"`)
    formData.append('duration', payload.duration)
    formData.append('ordering', payload.ordering)
    formData.append('status', payload.status)

    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
      'Content-Type': 'multipart/form-data',
    }

    for (const pair of formData.entries()) {
      console.log(pair[0] + ', ' + pair[1])
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, formData, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addModul(payload: any) {
    const endpoint = new this().endpoint + '/module'

    const formData = new FormData()
    formData.append('title', payload.title)
    formData.append('slug', payload.slug)
    formData.append('classroom_id', payload.classroomId.id)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('file', payload.file)
    formData.append('ordering', payload.ordering)
    formData.append('thumbnail', payload.thumbnail)

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: number): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().endpoint + '/subject'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}/show`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailModule(id: number) {
    const endpoint = new this().endpoint + '/module'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}/show`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: any
  ): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().endpoint + '/subject'

    const formData = new FormData()
    formData.append('title', payload.title)
    formData.append('slug', payload.slug)
    formData.append('classroom_id', payload.classroomId.id)
    formData.append('classroom_level_id', payload.classroomLevelId.id)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('video', payload.video)
    formData.append('video_link', payload.link)
    formData.append('published_at', `"` + payload.publishedAt + `"`)
    formData.append('duration', payload.duration)
    formData.append('ordering', payload.ordering)
    formData.append('status', payload.status)

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateModul(
    id: number,
    payload: any
  ): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().endpoint + '/module'

    const formData = new FormData()
    formData.append('title', payload.title)
    formData.append('slug', payload.slug)
    formData.append('classroom_id', payload.classroomId.id)
    formData.append('short_description', payload.shortDescription)
    formData.append('description', payload.description)
    formData.append('file', payload.file)
    formData.append('ordering', payload.ordering)
    formData.append('thumbnail', payload.thumbnail)

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number): Promise<ResourceResponse<Subject>> {
    const endpoint = new this().endpoint + '/subject'

    const {
      data: { data, success },
    } = await Http.delete(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
