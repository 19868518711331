import { CartDataInitialState } from './types'

export const GET_CART_DATA = 'GET_CART_DATA'
export const GET_CART_LOADING = 'GET_CART_LOADING'
export const GETTING_CATEGORY_DATA = 'GETTING_CATEGORY_DATA'
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA'
export const GET_CATEGORY_PAGE = 'GETTING_CATEGORY_PAGE'
export const GETTING_SELF_CLASSROOM_DATA = 'GETTING_SELF_CLASSROOM_DATA'
export const GET_SELF_CLASSROOM_DATA = 'GET_SELF_CLASSROOM_DATA'
export const GET_SELF_CLASSROOM_PAGE = 'GETTING_CLASSROOM_PAGE'
export const GET_MY_WEBINAR_DATA = 'GET_MY_WEBINAR_DATA'
export const GETTING_MY_WEBINAR_DATA = 'GETTING_MY_WEBINAR_DATA'

export default {
  [GET_CART_DATA](state: CartDataInitialState, values: Array<any>) {
    state.cartData = values
  },
  [GET_CART_LOADING](state: CartDataInitialState, values: boolean) {
    state.cartLoading = values
  },
}
