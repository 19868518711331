import { ActionTree } from 'vuex'
import { HomeDataInitialState } from './types'
import { State } from '@/main/store'
import {
  GETTING_CATEGORY_DATA,
  GET_CATEGORY_DATA,
  GET_CATEGORY_PAGE,
  GETTING_SELF_CLASSROOM_DATA,
  GET_SELF_CLASSROOM_DATA,
  GET_SELF_CLASSROOM_PAGE,
  GET_MY_WEBINAR_DATA,
  GETTING_MY_WEBINAR_DATA,
} from './mutations'
import Auth from '@/utils/Auth'

import { CategoryModel, ClassroomModel } from '@/models'

const actions: ActionTree<HomeDataInitialState, State> = {
  fetchCategories({ commit }) {
    return new Promise<void>((resolve, reject) => {
      commit(GETTING_CATEGORY_DATA, true)
      if (Auth.isLoggedIn()) {
        CategoryModel.listCategoryPrivate()
          .then(response => {
            commit(GET_CATEGORY_DATA, response.data)
            commit(GET_CATEGORY_PAGE, response.pages)
            commit(GETTING_CATEGORY_DATA, false)
            resolve()
          })
          .catch(error => {
            reject(error)
            console.log(error.statusText)
          })
      } else {
        CategoryModel.list()
          .then(response => {
            commit(GET_CATEGORY_DATA, response.data)
            commit(GET_CATEGORY_PAGE, response.pages)
            commit(GETTING_CATEGORY_DATA, false)
            resolve()
          })
          .catch(error => {
            reject(error)
            console.log(error.statusText)
          })
      }
    })
  },

  fetchMyClass({ commit }, { page, showPerPage }) {
    commit(GETTING_SELF_CLASSROOM_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.loadMyClassroomListV3()
        .then(response => {
          const { data, pages } = response
          commit(GET_SELF_CLASSROOM_DATA, data)
          commit(GET_SELF_CLASSROOM_PAGE, pages)
          // const { classrooms, pagination } = response.data
          // commit(GET_SELF_CLASSROOM_DATA, classrooms)
          // commit(GET_SELF_CLASSROOM_PAGE, pagination)
          commit(GETTING_SELF_CLASSROOM_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
  searchMyClass({ commit }, { page, showPerPage, term }) {
    commit(GETTING_SELF_CLASSROOM_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.searchMyClassroomByName(page, showPerPage, term)
        .then(response => {
          const { classrooms, pagination } = response.data
          commit(GET_SELF_CLASSROOM_DATA, classrooms)
          commit(GET_SELF_CLASSROOM_PAGE, pagination)
          commit(GETTING_SELF_CLASSROOM_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },

  fetchMyWebinar({ commit }) {
    commit(GETTING_MY_WEBINAR_DATA, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listMyWebinar()
        .then(response => {
          // console.log(response)
          commit(GET_MY_WEBINAR_DATA, response.data)
          commit(GETTING_MY_WEBINAR_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
          console.log(error.statusText)
        })
    })
  },
}

export default actions
