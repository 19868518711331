import cityStore from './city'

import homeStore from './home'
import sidebarStore from './sidebar'
import notificationStore from './notification'
import profileStore from './profile'
import topupStore from './topup'
import classroomStore from './classroom'
import reviewStore from './review'
import cartStore from './cart'
import mentorStore from './mentor'

const storeModules = {
  cityStore,
  homeStore,
  sidebarStore,
  notificationStore,
  profileStore,
  topupStore,
  classroomStore,
  reviewStore,
  cartStore,
  mentorStore,
}

export default storeModules
