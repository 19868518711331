import { GetterTree } from 'vuex'
import { HomeDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<HomeDataInitialState, State> = {
  getLoadingCategory: state => {
    return state.homeCategoryLoading
  },
  getCategories: state => {
    return state.homeCategoryData
  },
  getCategoriesPage: state => {
    return state.homeCategoryPages
  },
  getLoadingSelfClassroom: state => {
    return state.homeSelfClassroomDataLoading
  },
  getSelfClassrooms: state => {
    return state.homeSelfClassroomData
  },
  getSelfClassroomsPage: state => {
    return state.homeSelfClassroomDataPages
  },
  getLoadingMyWebinar: state => {
    return state.myWebinarDataLoading
  },
  getMyWebinar: state => {
    return state.myWebinarData
  },
}

export default getters
