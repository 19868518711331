import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import auth from './auth'
import storeModules from './modules'
import { Category } from '@/dto'
// import homeStore from './modules/home'
// import sidebarStore from './modules/sidebar'
// import notificationStore from './modules/notification'
// import profileStore from './modules'

export type State = {
  category: Category[]
}

const state: State = {
  category: [] as Category[],
}

const dataState = createPersistedState({
  paths: ['homeStore'],
})

export const store = createStore({
  ...auth,
  state,
  modules: {
    ...storeModules,
  },
  plugins: [dataState],
})
