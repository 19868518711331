import { MentorInitialState } from './types'
import { PrivateWebinarMentor } from '@/dto'

export const GET_PRIVATE_WEBINAR_MENTOR = 'GET_PRIVATE_WEBINAR_MENTOR'

export default {
  [GET_PRIVATE_WEBINAR_MENTOR](
    state: MentorInitialState,
    values: PrivateWebinarMentor[]
  ) {
    state.privateWebinarMentor = values
  },
}
