
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
})
