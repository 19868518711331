export default {
  path: '/',
  component: () =>
    import(
      /* webpackChunkName: 'App.Help.UserGuide' */ '@/main/views/app/help/Help.vue'
    ),
  children: [
    {
      path: '/user-guide',
      name: 'UserGuide',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.UserGuide' */ '@/main/views/app/help/UserGuide.vue'
        ),
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.Contact' */ '@/main/views/app/help/Contact.vue'
        ),
    },
    {
      path: '/why-sekdes',
      name: 'About',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.About' */ '@/main/views/app/help/About.vue'
        ),
    },
    {
      path: '/about-us',
      name: 'AboutUs',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.About' */ '@/main/views/app/help/About.vue'
        ),
    },
    {
      path: '/kebijakan',
      name: 'Privacy',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.Privacy' */ '@/main/views/app/help/Privacy.vue'
        ),
    },
    {
      path: '/sk',
      name: 'Terms',
      component: () =>
        import(
          /* webpackChunkName: 'App.Help.Terms' */ '@/main/views/app/help/Terms.vue'
        ),
    },
  ],
}
