import { Transaction } from '@/dto'
import Pagination from '@/dto/Response/Pagination'
import { TopupDataInitialState } from './types'

const state: TopupDataInitialState = {
  topupDataLoading: true,
  topupData: [] as Transaction[],
  topupDataPagination: {} as Pagination,
}

export default state
