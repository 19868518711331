/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */

import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Transaction, Payment } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
import Cookies from 'js-cookie'

export default class TransactionModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_TRANSACTION}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_TRANSACTION_PRIVATE}`

  public static async list(): Promise<ResourceResponse<Transaction[]>> {
    const privateEndpoint = new this().privateEndpoint + '/transaction'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listAllTransaction(
    perPage = 10,
    no = 1
  ): Promise<ResourceResponse<Transaction[]>> {
    const privateEndpoint =
      new this().privateEndpoint +
      '/transaction' +
      '?per_page=' +
      perPage +
      '&page=' +
      no

    const {
      data: { data, pages, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async filterTransaction(
    perPage: number,
    no: number,
    search: string,
    filter: string,
    start: Date,
    end: Date
  ): Promise<ResourceResponse<Transaction[]>> {
    const privateEndpoint =
      new this().privateEndpoint +
      '/transaction' +
      '?per_page=' +
      perPage +
      '&page=' +
      no +
      '&filter=' +
      filter +
      '&search=' +
      search +
      '&start=' +
      start +
      '&end=' +
      end +
      '&is_admin=true'

    const {
      data: { data, pages, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async downloadTransaction(
    perPage: number,
    start: Date,
    end: Date
  ) {
    const privateEndpoint =
      new this().privateEndpoint +
      '/transaction' +
      '?per_page=' +
      perPage +
      '&start=' +
      start +
      '&end=' +
      end +
      '&download=true'

    // console.log(privateEndpoint)
    return await Http.get(privateEndpoint, { responseType: 'blob' })
  }

  public static async listOneTransaction(id: any) {
    const privateEndpoint = new this().privateEndpoint + '/transaction/' + id

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async deleteTransaction(
    id: number
  ): Promise<ResourceResponse<Transaction>> {
    const privateEndpoint = new this().privateEndpoint + '/transaction/' + id
    const {
      data: { data, success },
    } = await Http.delete(privateEndpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async verifyTransaction(id: any) {
    const privateEndpoint =
      new this().privateEndpoint + '/transaction/' + id + '/verify'
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async rejectTransaction(id: any, payload: any) {
    const privateEndpoint =
      new this().privateEndpoint + '/transaction/' + id + '/reject'
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, payload)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listRegisteredTransaction(query: string) {
    const privateEndpoint =
      new this().privateEndpoint + '/transaction-per-day' + query

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detailRegisteredTransaction(query: string) {
    const privateEndpoint =
      new this().privateEndpoint + '/transaction-per-day' + query

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updateTransaction(id: any, paymentMethodId: number) {
    const privateEndpoint = new this().privateEndpoint + '/transaction/' + id
    const Payload = {
      payment_method_id: paymentMethodId,
    }
    const token = Cookies.getJSON('auth')

    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, Payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listPayment(): Promise<ResourceResponse<Payment[]>> {
    const privateEndpoint = new this().privateEndpoint + '/payment-method'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listPaymentPublic(): Promise<
    ResourceResponse<Payment[]>
  > {
    const privateEndpoint = new this().endpoint + '/payment-method'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOnePayment(
    id: number
  ): Promise<ResourceResponse<Payment[]>> {
    const privateEndpoint = new this().privateEndpoint + '/payment-method/' + id

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addPayment(payload: any) {
    const privateEndpoint = new this().privateEndpoint + '/payment-method'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('parent_id', payload.parentID)
    formData.append('code', payload.code)
    formData.append('account', payload.account)
    formData.append('instructions', payload.instructions)
    formData.append('icon', payload.icon)
    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async updatePayment(id: number, payload: any) {
    const privateEndpoint = new this().privateEndpoint + '/payment-method/' + id
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('parent_id', payload.parentID)
    formData.append('code', payload.code)
    formData.append('account', payload.account)
    formData.append('instructions', payload.instructions)
    formData.append('icon', payload.icon)
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addTransaction(
    code: {
      code: string
    }[],
    coupon = '' as string
  ) {
    const privateEndpoint = new this().privateEndpoint + '/transaction'
    const payload = {
      items: code,
      coupon,
    }
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addTransactionWithPayment(
    code: {
      code: string
    }[],
    payment: string,
    coupon = '' as string
  ) {
    const privateEndpoint = new this().privateEndpoint + '/transaction'
    const payload = {
      items: code,
      payment_code: payment,
      coupon: coupon,
    }
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addTransactionClass(code: any[]) {
    const privateEndpoint = new this().privateEndpoint + '/transaction'
    const payload = {
      items: code,
    }
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addTransactionSatuRupiah(code: any[]) {
    const privateEndpoint = new this().privateEndpoint + '/satu-rupiah'
    const payload = {
      items: code,
    }
    const token = Cookies.getJSON('auth')
    const headers = {
      Authorization: `Bearer ${token.token}`,
    }
    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, payload, { headers })

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(
    id: number
  ): Promise<ResourceResponse<Transaction>> {
    const privateEndpoint = new this().privateEndpoint + '/transaction'

    const {
      data: { data, success },
    } = await Http.get(`${privateEndpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Transaction
  ): Promise<ResourceResponse<Transaction>> {
    const privateEndpoint = new this().privateEndpoint + '/transaction'

    const {
      data: { data, success },
    } = await Http.put(`${privateEndpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<Transaction>> {
    const privateEndpoint = new this().privateEndpoint + '/transaction'

    const {
      data: { data, success },
    } = await Http.get(`${privateEndpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listTransactionConfirm(): Promise<
    ResourceResponse<Transaction[]>
  > {
    const privateEndpoint = new this().privateEndpoint + '/top-up-list'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listTransactionConfirmed(): Promise<
    ResourceResponse<Transaction[]>
  > {
    const privateEndpoint =
      new this().privateEndpoint +
      '/top-up-list?page_type=confirmed&per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listTransactionUnconfirmed(): Promise<
    ResourceResponse<Transaction[]>
  > {
    const privateEndpoint =
      new this().privateEndpoint +
      '/top-up-list?page_type=unconfirmed&per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listTopupExpired(): Promise<
    ResourceResponse<Transaction[]>
  > {
    const privateEndpoint =
      new this().privateEndpoint +
      '/top-up-list?page_type=expired&per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listOneTransactionCode(code: any) {
    const privateEndpoint = new this().privateEndpoint + '/transaction/' + code

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async sendPaymentConfirmation(id: number, payload: any) {
    const privateEndpoint =
      new this().privateEndpoint + '/transaction/' + id + '/proof'
    const formData = new FormData()
    formData.append('sender', payload.name)
    formData.append('proof', payload.file)
    formData.append('nominal_sent', payload.nominal)
    formData.append('sent_at', payload.date)
    const {
      data: { data, success },
    } = await Http.put(privateEndpoint, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listUserTransaction(uuid: any) {
    const privateEndpoint = new this().privateEndpoint + '/user/' + uuid

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getCart() {
    const privateEndpoint = new this().privateEndpoint + '/cart'
    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addToCart(payload: any[]) {
    const privateEndpoint = new this().privateEndpoint + '/cart'
    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async requestPaylater(payload: any) {
    const privateEndpoint = new this().privateEndpoint + '/paylater-request'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('whatsapp_pn', payload.whatsappPn)
    formData.append('email', payload.email)
    formData.append('relative_code', payload.relativeCode)
    formData.append('relative_name', payload.relativeName)
    formData.append('relative_pn', payload.relativePn)
    formData.append('identity_photo', payload.identityPhoto)
    formData.append('self_photo', payload.selfPhoto)

    const {
      data: { data, success },
    } = await Http.post(privateEndpoint, formData)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getQuotaPrice() {
    const privateEndpoint = new this().privateEndpoint + '/setting/quota-price'
    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addTransactionForQuota(payload: any) {
    const privateEnpoint = new this().privateEndpoint + '/top-up-private'
    const {
      data: { data, success },
    } = await Http.post(privateEnpoint, payload)

    return {
      success,
      data,
    }
  }

  public static async addTransactionForPackage(payload: any) {
    const privateEnpoint =
      new this().privateEndpoint + '/top-up-private/package'
    const {
      data: { data, success },
    } = await Http.post(privateEnpoint, payload)

    return {
      success,
      data,
    }
  }

  public static async getListWebinarPackage() {
    const endpoint = new this().privateEndpoint + '/package-top-up'
    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      data,
      success,
    }
  }
}
