import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRouter,
} from 'vue-router'

// import admin from '../admin/routes/index'
import app from '../main/routes/index'
import Auth from '@/utils/Auth'

const routes: Array<RouteRecordRaw> = [...app]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior: (to, from, savedPosition) => savedPosition ?? { top: 0 },
})

router.beforeEach((to, from) => {
  if (to.meta.requiresAuth && !Auth.isLoggedIn()) {
    return {
      path: '/login',
      query: { redirect: to.fullPath },
    }
  }
  if (
    to.meta.requiresAuth &&
    to.meta.adminOnly &&
    Auth.isLoggedIn() &&
    !Auth.isAdmin() &&
    !Auth.isMentor()
  ) {
    return {
      path: '/',
    }
  }
  if (to.meta.guestOnly && Auth.isLoggedIn()) {
    return {
      path: '/',
    }
  }
})

router.afterEach((to, from) => {
  console.log(document.title)
  document.title = to.meta.title || ''
})

export default router
