import { ReviewDataInitialState } from './types'
import ClassroomReview from '@/dto/ClassroomReview'
import ClassroomReviewSummary from '@/dto/ClassroomReviewSummary'
import Pagination from '@/dto/Response/Pagination'

const state: ReviewDataInitialState = {
  classroomReviewLoading: true,
  classroomReviewData: [] as ClassroomReview[],
  classroomReviewDataPages: {} as Pagination,

  classroomReviewSummaryLoading: true,
  classroomReviewSummaryData: {} as ClassroomReviewSummary,
}

export default state
