import { ActionTree } from 'vuex'
import { TopupDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GETTING_TOPUP_DATA,
  GET_TOPUP_DATA,
  GET_TOPUP_PAGINATION_DATA,
} from './mutations'

import TransactionModel from '@/models/TransactionModel'

const actions: ActionTree<TopupDataInitialState, State> = {
  fetchTopupData({ commit }, loadAmount = 10, page = 1) {
    commit(GETTING_TOPUP_DATA, true)
    return new Promise<void>((resolve, reject) => {
      TransactionModel.listAllTransaction(loadAmount, page)
        .then(response => {
          commit(GET_TOPUP_DATA, response.data)
          commit(GET_TOPUP_PAGINATION_DATA, response.pages)
          commit(GETTING_TOPUP_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
export default actions
