/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Cart } from '@/dto'
import MainApi from '@/utils/Http'
import { CartRequest } from '@/dto/Cart'
const Http = MainApi.getInstance()

export default class CartModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_TRANSACTION_PRIVATE}`

  public static async list(): Promise<ResourceResponse<Cart[]>> {
    const endpoint = new this().endpoint + '/cart'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async addSingle(
    code: string
  ): Promise<ResourceResponse<Cart[]>> {
    const endpoint = new this().endpoint + '/cart'

    const cartRequest = {
      items: [
        {
          code,
          amount: 1,
        },
      ],
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, cartRequest)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(
    payload: CartRequest
  ): Promise<ResourceResponse<Cart[]>> {
    const endpoint = new this().endpoint + '/cart'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async delete(code: string): Promise<ResourceResponse<Cart[]>> {
    const endpoint = new this().endpoint + '/cart/delete'

    const cartRequest = {
      items: [
        {
          code,
          amount: 1,
        },
      ],
    }

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, cartRequest)

    return {
      success,
      errors: [],
      data,
    }
  }
}
