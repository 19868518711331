import { ActionTree } from 'vuex'
import { ReviewDataInitialState } from './types'
import { State } from '@/main/store'

import {
  GET_CLASSROOM_REVIEW,
  GET_CLASSROOM_REVIEW_PAGE,
  GETTING_CLASSROOM_REVIEW_LOADING,
  GET_CLASSROOM_REVIEW_SUMMARY,
  GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING,
} from './mutations'

import ClassroomModel from '@/models/ClassroomModel'

const actions: ActionTree<ReviewDataInitialState, State> = {
  fetchClassroomReview({ commit }, { id, rating, showPerPage }) {
    commit(GETTING_CLASSROOM_REVIEW_LOADING, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listClassroomReview(id, rating, showPerPage)
        .then(response => {
          commit(GET_CLASSROOM_REVIEW, response.data)
          commit(GET_CLASSROOM_REVIEW_PAGE, response.pages)
          commit(GETTING_CLASSROOM_REVIEW_LOADING, false)
          resolve()
        })
        .catch(error => {
          commit(GETTING_CLASSROOM_REVIEW_LOADING, false)
          reject(error)
          console.log(error)
        })
    })
  },
  fetchClassroomReviewSummary({ commit }, { id }) {
    commit(GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING, true)
    return new Promise<void>((resolve, reject) => {
      ClassroomModel.listClassroomReviewSummary(id)
        .then(response => {
          commit(GET_CLASSROOM_REVIEW_SUMMARY, response.data)
          commit(GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING, false)
          resolve()
        })
        .catch(error => {
          commit(GETTING_CLASSROOM_REVIEW_SUMMARY_LOADING, false)
          reject(error)
          console.log(error)
        })
    })
  },
}

export default actions
