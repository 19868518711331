import { ActionTree } from 'vuex'
import { CityDataInitialState } from './types'
import { State } from '@/main/store'

import { GETTING_CITY_DATA, GET_CITY_DATA } from './mutations'

import CityModel from '@/models/CityModel'

const actions: ActionTree<CityDataInitialState, State> = {
  fetchCityList({ commit }) {
    commit(GETTING_CITY_DATA, true)
    return new Promise<void>((resolve, reject) => {
      CityModel.list()
        .then(response => {
          commit(GET_CITY_DATA, response)
          commit(GETTING_CITY_DATA, false)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}

export default actions
