import { GetterTree } from 'vuex'
import { ProfileDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<ProfileDataInitialState, State> = {
  getProfileDataLoading: state => {
    return state.profileDataUserLoading
  },
  getProfileData: state => {
    return state.profileDataUser
  },
  getProfileEditDataLoading: state => {
    return state.profileEditDataLoading
  },
}

export default getters
