import { GetterTree } from 'vuex'
import { TopupDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<TopupDataInitialState, State> = {
  getTopupDataLoading: state => {
    return state.topupDataLoading
  },
  getTopupData: state => {
    return state.topupData
  },
  getTopupDataPagination: state => {
    return state.topupDataPagination
  },
}

export default getters
