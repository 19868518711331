import { GetterTree } from 'vuex'
import { CityDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<CityDataInitialState, State> = {
  getCityDataLoading: state => {
    return state.cityDataLoading
  },
  getCityData: state => {
    return state.cityDataPayload
  },
}

export default getters
