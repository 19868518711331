// import { ClassroomItems } from '@/dto/ClassroomV2'
import { ClassroomMe } from '@/dto'
// import PaginationV2 from '@/dto/Response/PaginationV2'
import Pagination from '@/dto/Response/Pagination'
import { HomeDataInitialState } from './types'

const state: HomeDataInitialState = {
  homeCategoryLoading: true,
  homeCategoryData: [],
  homeCategoryPages: {},

  homeSelfClassroomDataLoading: true,
  // homeSelfClassroomData: [] as ClassroomItems[],
  homeSelfClassroomData: [] as ClassroomMe[],
  homeSelfClassroomDataPages: {} as Pagination,

  myWebinarDataLoading: true,
  myWebinarData: [],
}
export default state
