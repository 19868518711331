/* eslint-disable */
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosInstance,
} from 'axios'
import qs from 'qs'
import Auth from './Auth'
import router from '../router'

const baseURL = process.env.VUE_APP_BASE_URL

abstract class HttpClient {
  protected readonly instance: AxiosInstance
  protected idp = `${process.env.VUE_APP_IDP_URL}`
  protected secret = `${process.env.VUE_APP_IDP_CLIENT_SECRET}`

  public constructor() {
    this.instance = axios.create({
      baseURL,
      timeout: 1000 * 60, // 60 second
    })

    this._initializeResponseInterceptor()
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleError
    )
  }

  private _handleResponse = (res: AxiosResponse) => Promise.resolve(res)

  protected _handleError = async (error: any) => {
    // If timeout
    if (error.code === 'ECONNABORTED') {
      router.push('/timeout')
      return Promise.reject(error)
    }

    const { config, response: { status, data } } = error
    const originalRequest = config


    if (status === 401 &&
      data === 'invalid token\n') {
      await this.refreshToken()
      const retryOriginalRequest = new Promise((resolve) => {
        originalRequest.headers.Authorization = 'Bearer ' + Auth.getToken()?.token
        resolve(axios(originalRequest))
      })
      return retryOriginalRequest
    }

    return Promise.reject(error)
  } 

  private async refreshToken() {
    try {
      const sso = `${process.env.VUE_APP_IDP_URL}`
      const LoginData = qs.stringify({
        grant_type: 'refresh_token',
        client_id: 'kong',
        client_secret: process.env.VUE_APP_IDP_CLIENT_SECRET,
        refresh_token: Auth.getToken()?.refresh_token,
      })
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      }
      const res = await this.instance.post(`${sso}`, LoginData, { headers }).then(res => {
        const newToken = {
          token: res.data.access_token,
          refresh_token: res.data.refresh_token,
          is_imported: Auth.getToken()?.is_imported
        }
        Auth.setToken(newToken)
      })
    } catch(error) {
      const { _, response: { status } }: any = error
      if (status !== 200) {
        Auth.logout()
        window.location.assign("/register");
      }
    }
    
  }
}
export default class MainApi extends HttpClient {
  private static classInstance?: MainApi

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new MainApi()
    }
    return this.classInstance
  }

  public async get(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setDefaultConfig()
    return await this.instance.get(url, config)
  }

  public async post(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    this.setDefaultConfig()
    return await this.instance.post(url, data, config)
  }

  public async put(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await this.instance.put(url, data, config)
  }

  public async delete(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return await this.instance.delete(url, config)
  }

  public async downloadFile(url: string) {
    return await this.instance.get(url, {responseType: 'blob', headers: {
      'Content-type': 'application/pdf',
  }}, )
  }

  private setDefaultConfig() {
    this.instance.defaults.headers['Content-Type'] = 'application/json'
    if (Auth.getToken()) {
      this.instance.defaults.headers['Authorization'] =
        'Bearer ' + Auth.getToken()?.token
    }
  }
}
