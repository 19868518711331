import { Transaction } from '@/dto'
import Pagination from '@/dto/Response/Pagination'
import { TopupDataInitialState } from './types'

export const GETTING_TOPUP_DATA = 'GETTING_TOPUP_DATA'
export const GET_TOPUP_DATA = 'GET_TOPUP_DATA'
export const GET_TOPUP_PAGINATION_DATA = 'GET_TOPUP_PAGINATION_DATA'

export default {
  [GETTING_TOPUP_DATA](state: TopupDataInitialState, values: boolean) {
    state.topupDataLoading = values
  },
  [GET_TOPUP_DATA](state: TopupDataInitialState, values: Transaction[]) {
    state.topupData = values
  },
  [GET_TOPUP_PAGINATION_DATA](
    state: TopupDataInitialState,
    values: Pagination
  ) {
    state.topupDataPagination = values
  },
}
