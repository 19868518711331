import { Module } from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import { State } from '@/main/store'
import { NotificationDataInitialState } from './types'

const notificationStore: Module<NotificationDataInitialState, State> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

export default notificationStore
