/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Notification } from '@/dto'
import Cookies, { get } from 'js-cookie'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class NotificationModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_NOTIFICATION}`

  public static async listAll(): Promise<ResourceResponse<Notification[]>> {
    const endpoint = new this().endpoint + '/notification?per_page=1000'
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async list(): Promise<ResourceResponse<Notification[]>> {
    const endpoint = new this().endpoint + '/read-notification?per_page=100'
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async loadNotificationList(
    showItemOnLoad = 10
  ): Promise<ResourceResponse<Notification[]>> {
    const endpoint =
      new this().endpoint + `/read-notification?per_page=${showItemOnLoad}`
    const {
      data: { data, success, pages },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async readAll(): Promise<ResourceResponse<Notification[]>> {
    const endpoint = new this().endpoint + '/read-notification'
    const Payload = {
      read_all: true,
    }
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, Payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async readSingle(
    id: number
  ): Promise<ResourceResponse<Notification[]>> {
    const endpoint = new this().endpoint + '/read-notification'
    const Payload = {
      user_notification_id: id,
    }
    const {
      data: { data, success },
    } = await Http.put(`${endpoint}`, Payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async unreadAll(): Promise<ResourceResponse<Notification[]>> {
    const endpoint =
      new this().endpoint + '/read-notification/unread?per_page=100'
    const {
      data: { data, success },
    } = await Http.get(endpoint)
    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(
    payload: Notification
  ): Promise<ResourceResponse<Notification>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(
    id: number
  ): Promise<ResourceResponse<Notification>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/show/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Notification
  ): Promise<ResourceResponse<Notification>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<Notification>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
