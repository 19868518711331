import { City } from '@/dto'
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { CityDataInitialState } from './types'

const state: CityDataInitialState = {
  cityDataLoading: true,
  cityDataPayload: {} as ResourceResponse<City[]>,
}

export default state
