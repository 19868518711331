/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Accessory } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class AccessoryModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_ACCESSORIES}`

  public static async list(
    token: string
  ): Promise<ResourceResponse<Accessory[]>> {
    const endpoint =
      new this().endpoint + '/accessories/show-accessories-user?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async loadAccessoriesList(
    page = 1,
    showPerPage = 10
  ): Promise<ResourceResponse<Accessory[]>> {
    const endpoint =
      new this().endpoint +
      `/accessories/show-accessories-user?page=${page}&per_page=${showPerPage}`

    const {
      data: { data, success, pages },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listAccessoriesAdmin(
    token: string
  ): Promise<ResourceResponse<Accessory[]>> {
    const endpoint = new this().endpoint + '/accessories/show-accessories'
    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async buy(id: number, price: string, token: string) {
    const endpoint = new this().endpoint + '/user-accessories/add'

    const accessoryData = {
      price: price,
      accessory_id: id,
    }

    const {
      data: { message, success },
    } = await Http.post(`${endpoint}`, accessoryData)

    return {
      success,
      errors: [],
      message,
    }
  }

  public static async add(
    payload: Accessory
  ): Promise<ResourceResponse<Accessory>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneAccessories(
    id: number
  ): Promise<ResourceResponse<Accessory>> {
    const endpoint = new this().endpoint + '/accessories/show'

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Accessory
  ): Promise<ResourceResponse<Accessory>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.put(`${endpoint}/${id}`, payload)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(
    id: number
  ): Promise<ResourceResponse<Accessory>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
