import { NotificationDataInitialState } from './types'
import Notification from '@/dto/Notification'
import Pagination from '@/dto/Response/Pagination'

export const GETTING_NOTIFICATION_DATA = 'GETTING_NOTIFICATION_DATA'
export const GET_NOTIFICATION_DATA = 'GET_NOTIFICATION_DATA'
export const GET_NOTIFICATION_PAGINATION_DATA =
  'GET_NOTIFICATION_PAGINATION_DATA'
export const GET_UNREAD_NOTIFICATION_DATA = 'GET_UNREAD_NOTIFICATION_DATA'
export const READ_SINGLE_NOTIFICATION = 'READ_SINGLE_NOTIFICATION'

/* eslint-disable @typescript-eslint/camelcase */
export default {
  [GETTING_NOTIFICATION_DATA](
    state: NotificationDataInitialState,
    values: boolean
  ) {
    state.notificationDataLoading = values
  },

  [GET_NOTIFICATION_DATA](
    state: NotificationDataInitialState,
    values: Notification[]
  ) {
    state.notificationData = values
  },

  [GET_NOTIFICATION_PAGINATION_DATA](
    state: NotificationDataInitialState,
    values: Pagination
  ) {
    state.notificationDataPagination = values
  },

  [GET_UNREAD_NOTIFICATION_DATA](
    state: NotificationDataInitialState,
    values: Notification[]
  ) {
    state.unReadNotificationData = values
  },
  [READ_SINGLE_NOTIFICATION](state: NotificationDataInitialState, id: number) {
    if (state.unReadNotificationData.length > 0) {
      const filter = state.unReadNotificationData.filter((el: any) => {
        return el.id != id
      })
      state.unReadNotificationData = filter
    }
    if (state.notificationData.length > 0) {
      state.notificationData.forEach((el: any) => {
        if (el.id == id) {
          el.is_read = true
        }
      })
    }
  },
}
