/* eslint-disable @typescript-eslint/camelcase */
import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Coupon } from '@/dto'
import MainApi from '@/utils/Http'
import { CouponRequest } from '@/dto/Coupon'
const Http = MainApi.getInstance()

export default class CouponModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` + `${process.env.VUE_APP_SERVICE_VOUCHER}`

  public static async check(
    couponRequest: CouponRequest
  ): Promise<ResourceResponse<Coupon>> {
    const endpoint = new this().endpoint + '/coupon/check-coupon'

    const {
      data: { data, success },
    } = await Http.post(`${endpoint}`, couponRequest)

    return {
      success,
      errors: [],
      data,
    }
  }
}
