import ResourceResponse from '@/dto/Response/ResourceResponse'
import { Category } from '@/dto'
import MainApi from '@/utils/Http'
const Http = MainApi.getInstance()
export default class CategoryModel {
  protected endpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM}`

  protected privateEndpoint =
    `${process.env.VUE_APP_BASE_URL}` +
    `${process.env.VUE_APP_SERVICE_CLASSROOM_PRIVATE}`

  public static async list(): Promise<ResourceResponse<Category[]>> {
    const endpoint = new this().endpoint + '/category'

    const {
      data: { data, success },
    } = await Http.get(endpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async listCategoryPrivate(): Promise<
    ResourceResponse<Category[]>
  > {
    const privateEndpoint = new this().privateEndpoint + '/category'

    const {
      data: { data, success, pages },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
      pages,
    }
  }

  public static async listCategoryAdmin(): Promise<
    ResourceResponse<Category[]>
  > {
    const privateEndpoint =
      new this().privateEndpoint + '/category?per_page=1000'

    const {
      data: { data, success },
    } = await Http.get(privateEndpoint)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async add(
    payload: Category
  ): Promise<ResourceResponse<Category>> {
    const privateEndpoint = new this().privateEndpoint + '/category'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('thumbnail', payload?.thumbnail)
    formData.append('short_description', payload.name)
    formData.append('description', payload.description)

    const {
      data: { data, success },
    } = await Http.post(`${privateEndpoint}`, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async detail(id: number): Promise<ResourceResponse<Category>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async getOneCategory(
    id: number
  ): Promise<ResourceResponse<Category>> {
    const privateEndpoint = new this().privateEndpoint + '/category'

    const {
      data: { data, success },
    } = await Http.get(`${privateEndpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async update(
    id: number,
    payload: Category
  ): Promise<ResourceResponse<Category>> {
    const privateEndpoint = new this().privateEndpoint + '/category'
    const formData = new FormData()
    formData.append('name', payload.name)
    formData.append('slug', payload.slug)
    formData.append('thumbnail', payload.thumbnail)
    formData.append('short_description', payload.name)
    formData.append('description', payload.description)
    const {
      data: { data, success },
    } = await Http.put(`${privateEndpoint}/${id}`, formData)

    return {
      success,
      errors: [],
      data,
    }
  }

  public static async destroy(id: number): Promise<ResourceResponse<Category>> {
    const endpoint = new this().endpoint

    const {
      data: { data, success },
    } = await Http.get(`${endpoint}/${id}`)

    return {
      success,
      errors: [],
      data,
    }
  }
}
