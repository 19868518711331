import { GetterTree } from 'vuex'
import { CartDataInitialState } from './types'
import { State } from '@/main/store'

const getters: GetterTree<CartDataInitialState, State> = {
  getCartLoading: state => {
    return state.cartLoading
  },
  getCartData: state => {
    return state.cartData
  },
}

export default getters
