import help from '@/main/routes/help'
import error404 from '@/main/routes/error404'
import auth from '@/main/routes/auth'
import profile from '@/main/routes/profile'
import mentor from '@/main/routes/mentor'
import schedule from '@/main/routes/schedule'
import classroom from '@/main/routes/classroom'
import notifications from '@/main/routes/notifications'
import checkout from '@/main/routes/checkout'
import cart from './cart'
import paylater from './paylater'
import errorTimeout from '@/main/routes/errorTimeout'
import mentorNew from '@/main/routes/mentorNew'

export default [
  {
    path: '/',
    component: () =>
      import(
        /* webpackChunkName: 'App.MainLayout' */ '@/main/views/app/_Layout/Member/MainLayout.vue'
      ),
    props: true,
    children: [
      {
        path: '/',
        name: 'Home',
        component: () =>
          import(
            /* webpackChunkName: 'App.Home' */ '@/main/views/app/_Main/Home.vue'
          ),
        props: true,
      },
      {
        path: '/sk-paylater',
        name: 'SKPaylater',
        component: () =>
          import(
            /* webpackChunkName: 'SKPaylater.Home' */ '@/main/views/app/SKPaylater.vue'
          ),
      },
      {
        path: '/my-classes',
        name: 'MyClasses',
        component: () =>
          import(
            /* webpackChunkName: 'App.MyClasses' */ '@/main/views/app/_Main/MyClasses.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () =>
          import(
            /* webpackChunkName: 'App.Profile' */ '@/main/views/app/_Main/Profile.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: '/notification',
        name: 'Notification',
        component: () =>
          import(
            /* webpackChunkName: 'App.Notification' */ '@/main/views/app/_Main/Notification.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: '/search',
        name: 'Search',
        component: () =>
          import(
            /* webpackChunkName: 'App.Search' */ '@/main/views/app/_Main/Search.vue'
          ),
        meta: {
          requiresAuth: true,
        },
        props: true,
      },
      {
        path: '/category',
        name: 'Category',
        component: () =>
          import(
            /* webpackChunkName: 'App.Category' */ '@/main/views/app/_Main/Category.vue'
          ),
        props: true,
      },
      {
        path: '/classroom/:code',
        name: 'App.Main.Course',
        component: () =>
          import(
            /* webpackChunkName: 'App.Main.Course' */ '@/main/views/app/_Main/Classroom/Detail.vue'
          ),
        props: true,
      },
      {
        path: '/payment/:code',
        name: 'Payment',
        component: () =>
          import(
            /* webpackChunkName: 'Payment' */ '@/main/views/app/_Main/Payment/Confirmation.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () =>
          import(
            /* webpackChunkName: 'Cart' */ '@/main/views/app/_Main/Cart.vue'
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/download',
        name: 'DownloadApp',
        component: () =>
          import(
            /* webpackChunkName: 'DownloadApp' */ '@/main/views/app/Download.vue'
          ),
      },
      ...auth,
      {
        path: '/add-to-cart',
        name: 'AddToCart',
        component: () =>
          import(
            /* webpackChunkName: 'AddToCart' */ '@/main/views/app/_Main/AddToCart.vue'
          ),
      },
      {
        path: '/webinar/:slug',
        name: 'WebinarCheckout',
        component: () =>
          import(
            /* webpackChunkName: 'WebinarCheckout' */ '@/main/views/app/_Main/WebinarCheckout.vue'
          ),
      },
      {
        path: '/langganan',
        name: 'Langganan',
        component: () =>
          import(
            /* webpackChunkName: 'Langganan' */ '@/main/views/app/_Main/Langganan.vue'
          ),
      },
      // ...profile,
      // ...notifications,
      // ...classroom,
      // ...schedule,
      // ...checkout,
      // ...cart,
      // ...paylater,
      help,
      error404,
    ],
    meta: {
      title: 'Sekolah Desain (sekolahdesain.id)',
    },
  },
  {
    path: '/classroom/:code/activity',
    name: 'App.Main.Course.Activity',
    component: () =>
      import(
        /* webpackChunkName: 'App.Main.Course.Activity' */ '@/main/views/app/_Main/Classroom/Activity.vue'
      ),
    props: true,
    meta: {
      requiresAuth: true,
      title: 'Sekolah Desain (sekolahdesain.id)',
    },
  },
  {
    path: '/mentor',
    name: 'App.Mentor',
    component: () =>
      import(
        /* webpackChunkName: 'App.MentorNew' */ '@/main/views/app/_Layout/Mentor/MainLayout.vue'
      ),
    children: [...mentorNew],
  },
  // {
  //   path: '/webinar-master',
  //   name: 'WebinarMaster',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'App.WebinarMaster' */ '@/main/views/app/WebinarMaster.vue'
  //     ),
  // },
  // {
  //   path: '/bundle/:code',
  //   name: 'Bundle',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'App.WebinarMaster' */ '@/main/views/app/Bundle.vue'
  //     ),
  // },
  // {
  //   path: '/one-rup',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: 'App.MainLayout' */ '@/main/views/app/_Layout/MainLayout.vue'
  //     ),
  //   children: [
  //     {
  //       path: '/one-rupiah',
  //       name: 'OneRupiah',
  //       component: () =>
  //         import(
  //           /* webpackChunkName: 'App.OneRupiah' */ '@/main/views/app/OneRupiah.vue'
  //         ),
  //     },
  //   ],
  // },
  {
    path: '/:catchAll(.*)',
    component: () =>
      import(
        /* webpackChunkName: 'App.MainLayout' */ '@/main/views/errors/404.vue'
      ),
  },
  errorTimeout,
]
